import React, { Component } from 'react';
import { Row, Col, Form, Button, Modal, Table, Spinner, ProgressBar } from 'react-bootstrap';
import 'react-dates/initialize';
import { getFormattedDate } from '../../includes/HelperMpSources';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import '../../assets/style/mp-sources.css';

class ModalDetails extends Component {
  state = {
    isPaid: false,

    isBKPaymentReceived: false,
    isBKFreightPaid: false,
    isBKPaymentSent: false,
    isBKCommission: false,
    isBKFreightAdjustment: false,
    isBKRefund: false,
    isBKProfitProcessed: false,

    freightBookedOn: '',
    freightPaidOn: '',
    paymentReceivedOn: '',
    freightAdjustmentOn: '',
    reimbursedOn: '',
    refundOn: '',
    refundFreightOn: '',
    profitSentToScoutIQOn: '',
  };

  constructor() {
    super();
    this.handleOpen = this.handleOpen.bind(this);
    this.onFormSubmitUpdateMPBatch = this.onFormSubmitUpdateMPBatch.bind(this);
  }

  async onFormSubmitUpdateMPBatch(e) {
    e.preventDefault();

    const dates = {
      freightBookedOn: getFormattedDate(this.state.freightBookedOn),
      freightPaidOn: getFormattedDate(this.state.freightPaidOn),
      paymentSentOn: getFormattedDate(this.state.paymentSentOn),
      paymentReceivedOn: getFormattedDate(this.state.paymentReceivedOn),
      freightAdjustmentOn: getFormattedDate(this.state.freightAdjustmentOn),
      reimbursedOn: getFormattedDate(this.state.reimbursedOn),
      refundOn: getFormattedDate(this.state.refundOn),
      refundFreightOn: getFormattedDate(this.state.refundFreightOn),
      profitSentToScoutIQOn: getFormattedDate(this.state.profitSentToScoutIQOn),
    };

    this.props.onFormSubmitUpdateMPBatch(e, dates);
  }

  handleOpen() {
    this.setState({
      isPaid: false,

      isBKFreightPaid: false,
      isBKPaymentSent: false,
      isBKPaymentReceived: false,
      isBKCommission: false,
      isBKFreightAdjustment: false,
      isBKRefund: false,
      isBKProfitProcessed: false,
      freightBookedOn: '',
      freightPaidOn: '',
      paymentReceivedOn: '',
      paymentSentOn: '',
      freightAdjustmentOn: '',
      reimbursedOn: '',
      refundOn: '',
      refundFreightOn: '',
      profitSentToScoutIQOn: '',
    });
    if (this.props.selectedBatch)
      this.setState({
        isPaid: this.props.selectedBatch.freight_paid === 1 ? true : false,
        isBKFreightPaid: this.props.selectedBatch.verified_freight,
        isBKPaymentSent: this.props.selectedBatch.verified_payment_sent,
        isBKPaymentReceived: this.props.selectedBatch.verified_payment_received,
        isBKCommission: this.props.selectedBatch.verified_commissions,
        isBKFreightAdjustment: this.props.selectedBatch.verified_adjustments,
        isBKRefund: this.props.selectedBatch.verified_refunds,
        isBKProfitProcessed: this.props.selectedBatch.verified_profits,

        freightBookedOn: this.props.selectedBatch.freight_booked_date
          ? moment(this.props.selectedBatch.freight_booked_date)
          : '',
        paymentSentOn: this.props.selectedBatch.payment_sent_date
          ? moment(this.props.selectedBatch.payment_sent_date)
          : '',
        freightPaidOn: this.props.selectedBatch.freight_paid_date
          ? moment(this.props.selectedBatch.freight_paid_date)
          : '',
        paymentReceivedOn: this.props.selectedBatch.payment_received_date
          ? moment(this.props.selectedBatch.payment_received_date)
          : '',
        freightAdjustmentOn: this.props.selectedBatch.freight_adjustment_date
          ? moment(this.props.selectedBatch.freight_adjustment_date)
          : '',
        reimbursedOn: this.props.selectedBatch.reimbursed_date ? moment(this.props.selectedBatch.reimbursed_date) : '',
        refundOn: this.props.selectedBatch.refund_on ? moment(this.props.selectedBatch.refund_on) : '',
        refundFreightOn: this.props.selectedBatch.refund_freight_on
          ? moment(this.props.selectedBatch.refund_freight_on)
          : '',
        profitSentToScoutIQOn: this.props.selectedBatch.profit_sent_to_siq_on
          ? moment(this.props.selectedBatch.profit_sent_to_siq_on)
          : '',
        // isBKPaymentReceived:
      });
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          onEntered={this.handleOpen}
          show={this.props.showMPModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Form onSubmit={this.onFormSubmitUpdateMPBatch}>
            <Modal.Header closeButton>
              <div style={{ flex: 1, flexDirection: 'column' }}>
                <div>
                  <h2>{this.props.selectedBatch.name}</h2>
                </div>
                <div>
                  <Form.Control
                    type="text"
                    step="0.01"
                    name="mpBatchNotes"
                    max={100}
                    defaultValue={this.props.selectedBatch.pallet_notes}
                    style={{
                      width: '100%',
                      borderRadius: '0',
                      border: 'none',
                      borderBottom: '1px solid #ccc',
                    }}
                    placeholder="Enter notes here"
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      Value: <b>${this.props.selectedBatch.pallet_value}</b>
                    </div>
                    <div style={{ flex: 1 }}>
                      Filled: <b>${parseFloat(this.props.selectedBatch.agg).toFixed(0)}</b>
                    </div>
                    <div style={{ flex: 2 }}>
                      <Form.Control as="select" name="teamId" defaultValue={this.props.selectedBatch.team_id}>
                        {this.props.teams.map((teamGroup) => {
                          return <option value={teamGroup.id}>{teamGroup.team_name}</option>;
                        })}
                      </Form.Control>
                    </div>
                    <div style={{ flex: 2 }}>
                      <ProgressBar
                        style={{ marginLeft: '15px' }}
                        variant={
                          this.props.selectedBatch.per_completed < 1
                            ? 'danger'
                            : this.props.selectedBatch.per_completed >= 1 && this.props.selectedBatch.per_completed < 25
                            ? 'warning'
                            : this.props.selectedBatch.per_completed >= 25 &&
                              this.props.selectedBatch.per_completed < 99
                            ? 'info'
                            : 'success'
                        }
                        now={this.props.selectedBatch.per_completed < 1 ? 10 : this.props.selectedBatch.per_completed}
                        label={`${this.props.selectedBatch.per_completed}%`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Header>

            <Modal.Body>
              <div style={{ display: 'flex', fontSize: '13px' }}>
                <div style={{ flex: 1 }}>
                  <Form.Check
                    type="switch"
                    name="isCompleted"
                    id="is-completed"
                    label="Completed?"
                    defaultChecked={this.props.selectedBatch.completed === 1 ? true : false}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <Form.Check
                    type="switch"
                    defaultChecked={this.props.selectedBatch.shipped === 1 ? true : false}
                    name="isShipped"
                    id="is-shipped"
                    label="Shipped?"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Check
                    type="switch"
                    name="isPaid"
                    id="is-paid"
                    label="Paid?"
                    defaultChecked={this.props.selectedBatch.paid === 1 ? true : false}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Check
                    style={{ fontWeight: 'bold' }}
                    type="switch"
                    name="isCaseClosed"
                    id="is-case-closed"
                    label="Case Closed"
                    defaultChecked={this.props.selectedBatch.is_case_closed === 1 ? true : false}
                  />
                </div>
              </div>

              {/* Payment Received */}
              <div
                style={{
                  fontSize: '13px',
                  marginTop: '15px',
                  padding: '14px 7px 15px 7px',
                  borderBottom: '1px solid #ddd',
                  background: this.state.isBKPaymentReceived ? '#f1fef4' : '#edeef0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 1.5, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Payment Received On</Form.Label>
                      <SingleDatePicker
                        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                        displayFormat={'MM-DD-YYYY'}
                        numberOfMonths={1}
                        date={this.state.paymentReceivedOn}
                        onDateChange={(date) => this.setState({ paymentReceivedOn: date })} // PropTypes.func.isRequired
                        focused={this.state.focused3} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ focused3: focused })} // PropTypes.func.isRequired
                        id="your_unique_id_10" // PropTypes.string.isRequired,
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Amount Received</Form.Label>
                      <Form.Control
                        type="text"
                        name="paymentReceived"
                        defaultValue={this.props.selectedBatch.payment_received_amt}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1.5, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Payment ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="paymentReceivedID"
                        defaultValue={this.props.selectedBatch.payment_received_id}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <Form.Check
                    className="checks-mp"
                    type="checkbox"
                    name="isBKPaymentReceived"
                    id="is-payment-received"
                    label="Payment Received from Buyer - Bookkeeping Verified"
                    onChange={(e) => this.setState({ isBKPaymentReceived: e.target.checked })}
                    defaultChecked={this.props.selectedBatch.verified_payment_received}
                  />
                </div>
              </div>

              {/* Freight Booked On */}
              <div
                style={{
                  fontSize: '13px',
                  padding: '14px 7px 15px 7px',
                  borderBottom: '1px solid #ddd',
                  borderTop: '1px solid #ddd',
                  background: this.state.isBKFreightPaid ? '#f1fef4' : '#edeef0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 1.5, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Freight Booked on</Form.Label>
                      <SingleDatePicker
                        name="freightBookedOn"
                        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                        displayFormat={'MM-DD-YYYY'}
                        numberOfMonths={1}
                        date={this.state.freightBookedOn}
                        onDateChange={(date) => this.setState({ freightBookedOn: date })} // PropTypes.func.isRequired
                        focused={this.state.focused} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                        id="your_unique_id" // PropTypes.string.isRequired,
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Freight Cost</Form.Label>
                      <Form.Control
                        type="text"
                        name="freightCost"
                        defaultValue={this.props.selectedBatch.freight_cost}
                      />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Freight Charge</Form.Label>
                      <Form.Control
                        type="text"
                        name="freightCharge"
                        defaultValue={this.props.selectedBatch.freight_charge}
                      />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1.5, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Buyer Paid on</Form.Label>
                      <SingleDatePicker
                        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                        displayFormat={'MM-DD-YYYY'}
                        numberOfMonths={1}
                        date={this.state.freightPaidOn}
                        onDateChange={(date) => this.setState({ freightPaidOn: date })} // PropTypes.func.isRequired
                        focused={this.state.focused2} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ focused2: focused })} // PropTypes.func.isRequired
                        id="your_unique_id_2" // PropTypes.string.isRequired,
                      />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Freight Profit</Form.Label>
                      <Form.Control
                        type="text"
                        name="freightProfit"
                        defaultValue={this.props.selectedBatch.freight_profit}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <Form.Check
                    className="checks-mp"
                    type="checkbox"
                    name="isBKFreightPaid"
                    id="is-freight-paid"
                    label="Freight - Bookkeeping Verified"
                    onChange={(e) => this.setState({ isBKFreightPaid: e.target.checked })}
                    defaultChecked={this.props.selectedBatch.verified_freight}
                  />
                </div>
              </div>

              {/* Payment Sent */}
              <div
                style={{
                  fontSize: '13px',
                  padding: '14px 7px 15px 7px',
                  borderBottom: '1px solid #ddd',
                  background: this.state.isBKPaymentSent ? '#f1fef4' : '#edeef0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 1.5, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Payment Sent On</Form.Label>
                      <SingleDatePicker
                        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                        displayFormat={'MM-DD-YYYY'}
                        numberOfMonths={1}
                        date={this.state.paymentSentOn}
                        onDateChange={(date) => this.setState({ paymentSentOn: date })} // PropTypes.func.isRequired
                        focused={this.state.focusedpaymentSentOn} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ focusedpaymentSentOn: focused })} // PropTypes.func.isRequired
                        id="your_unique_id_paymentSentOn" // PropTypes.string.isRequired,
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Amount Sent</Form.Label>
                      <Form.Control
                        type="number"
                        step="any"
                        name="paymentSent"
                        defaultValue={this.props.selectedBatch.payment_sent}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <Form.Check
                    className="checks-mp"
                    type="checkbox"
                    name="isBKPaymentSent"
                    id="is-payment-sent"
                    label="Payment Sent to Supplier - Bookkeeping Verified"
                    onChange={(e) => this.setState({ isBKPaymentSent: e.target.checked })}
                    defaultChecked={this.props.selectedBatch.verified_payment_sent}
                  />
                </div>
              </div>

              {/* Buyer Comission */}
              <div
                style={{
                  fontSize: '13px',
                  padding: '14px 7px 15px 7px',
                  borderBottom: '1px solid #ddd',
                  background: this.state.isBKCommission ? '#f1fef4' : '#edeef0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Buyer Commission</Form.Label>
                      <Form.Control
                        type="text"
                        name="buyerCommission"
                        defaultValue={this.props.selectedBatch.buyer_commission}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Seller Commission</Form.Label>
                      <Form.Control
                        type="text"
                        name="sellerCommission"
                        defaultValue={this.props.selectedBatch.seller_commission}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <Form.Check
                    className="checks-mp"
                    type="checkbox"
                    name="isCommisions"
                    id="is-commisions"
                    label="Commisions - Bookkeeping Verified"
                    onChange={(e) => this.setState({ isBKCommission: e.target.checked })}
                    defaultChecked={this.props.selectedBatch.verified_commissions}
                  />
                </div>
              </div>

              {/* Profit Sent to ScoutIQ */}
              <div
                style={{
                  fontSize: '13px',
                  padding: '14px 7px 15px 7px',
                  borderBottom: '1px solid #ddd',
                  background: this.state.isBKProfitProcessed ? '#f1fef4' : '#edeef0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Profit Sent to ScoutIQ on</Form.Label>
                      <SingleDatePicker
                        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                        displayFormat={'MM-DD-YYYY'}
                        numberOfMonths={1}
                        date={this.state.profitSentToScoutIQOn}
                        onDateChange={(date) => this.setState({ profitSentToScoutIQOn: date })} // PropTypes.func.isRequired
                        focused={this.state.focused9} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ focused9: focused })} // PropTypes.func.isRequired
                        id="your_unique_id_profitSentToScoutIQOn" // PropTypes.string.isRequired,
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Total Profit</Form.Label>
                      <Form.Control
                        type="text"
                        name="totalProfit"
                        defaultValue={this.props.selectedBatch.total_profit}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <Form.Check
                    className="checks-mp"
                    type="checkbox"
                    name="isBKProfitProcessed"
                    id="is-profits"
                    label="Profits - Bookkeeping Verified"
                    onChange={(e) => this.setState({ isBKProfitProcessed: e.target.checked })}
                    defaultChecked={this.props.selectedBatch.verified_profits}
                  />
                </div>
              </div>

              {/* Freight Adjustments */}
              <div
                style={{
                  fontSize: '13px',
                  padding: '14px 7px 15px 7px',
                  borderBottom: '1px solid #ddd',
                  background: this.state.isBKFreightAdjustment ? '#f1fef4' : '#edeef0',
                }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ flex: 1, paddingRight: '10px' }}>
                      <Form.Group controlId="formFirstName">
                        <Form.Label>Freight Adjustment Date</Form.Label>
                        <SingleDatePicker
                          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                          displayFormat={'MM-DD-YYYY'}
                          numberOfMonths={1}
                          date={this.state.freightAdjustmentOn}
                          onDateChange={(date) => this.setState({ freightAdjustmentOn: date })} // PropTypes.func.isRequired
                          focused={this.state.focusedfreightAdjustmentOn} // PropTypes.bool
                          onFocusChange={({ focused }) => this.setState({ focusedfreightAdjustmentOn: focused })} // PropTypes.func.isRequired
                          id="your_unique_freightAdjustmentOn" // PropTypes.string.isRequired,
                        />
                      </Form.Group>
                    </div>
                    <div style={{ flex: 1, paddingRight: '10px' }}>
                      <Form.Group controlId="formFirstName">
                        <Form.Label>Adjustment Amount</Form.Label>
                        <Form.Control
                          type="text"
                          name="freightAdjustmentAmount"
                          defaultValue={this.props.selectedBatch.freight_adjustment_amt}
                        />
                      </Form.Group>
                    </div>

                    <div style={{ flex: 1, paddingRight: '10px' }}>
                      <Form.Group controlId="formFirstName">
                        <Form.Label>Buyer Reimbursed on</Form.Label>
                        <SingleDatePicker
                          isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                          displayFormat={'MM-DD-YYYY'}
                          numberOfMonths={1}
                          date={this.state.reimbursedOn}
                          onDateChange={(date) => this.setState({ reimbursedOn: date })} // PropTypes.func.isRequired
                          focused={this.state.focusedBuyerReimbursedOn} // PropTypes.bool
                          onFocusChange={({ focused }) => this.setState({ focusedBuyerReimbursedOn: focused })} // PropTypes.func.isRequired
                          id="your_unique_id_buyer_reimbursed_on" // PropTypes.string.isRequired,
                        />
                      </Form.Group>
                    </div>

                    <div style={{ flex: 1 }}>
                      <Form.Group controlId="formFirstName">
                        <Form.Label>Reimbursed Amount</Form.Label>
                        <Form.Control
                          type="text"
                          name="reimbursedAmount"
                          defaultValue={this.props.selectedBatch.reimbursed_amt}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div style={{ marginTop: '7px' }}>
                    <div style={{ flex: 1 }}>
                      <Form.Group controlId="formFirstName">
                        <Form.Label>Payment ID</Form.Label>
                        <Form.Control
                          type="text"
                          name="reimbursedAmountPaymentID"
                          defaultValue={this.props.selectedBatch.reimbursed_id}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <Form.Check
                    className="checks-mp"
                    type="checkbox"
                    name="isBKFreightAdjustments"
                    id="is-freight-adjustment"
                    label="Freight Adjustments - Bookkeeping Verified"
                    onChange={(e) => this.setState({ isBKFreightAdjustment: e.target.checked })}
                    defaultChecked={this.props.selectedBatch.verified_adjustments}
                  />
                </div>
              </div>

              {/* Refunds */}
              <div
                style={{
                  fontSize: '13px',
                  padding: '14px 7px 15px 7px',
                  borderBottom: '1px solid #ddd',
                  background: this.state.isBKRefund ? '#f1fef4' : '#edeef0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Refund Date</Form.Label>
                      <SingleDatePicker
                        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                        displayFormat={'MM-DD-YYYY'}
                        numberOfMonths={1}
                        date={this.state.refundOn}
                        onDateChange={(date) => this.setState({ refundOn: date })} // PropTypes.func.isRequired
                        focused={this.state.focused5} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ focused5: focused })} // PropTypes.func.isRequired
                        id="your_unique_id" // PropTypes.string.isRequired,
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Refund Amount</Form.Label>
                      <Form.Control
                        type="text"
                        name="refundAmount"
                        defaultValue={this.props.selectedBatch.refund_amt}
                      />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: '10px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Refund Freight on</Form.Label>
                      <SingleDatePicker
                        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                        displayFormat={'MM-DD-YYYY'}
                        numberOfMonths={1}
                        date={this.state.refundFreightOn}
                        onDateChange={(date) => this.setState({ refundFreightOn: date })} // PropTypes.func.isRequired
                        focused={this.state.focused6} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ focused6: focused })} // PropTypes.func.isRequired
                        id="your_unique_id_2" // PropTypes.string.isRequired,
                      />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Refund Freight Amount</Form.Label>
                      <Form.Control
                        type="text"
                        name="refundFreightAmount"
                        defaultValue={this.props.selectedBatch.refund_freight_amt}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div style={{ marginTop: '5px' }}>
                  <Form.Check
                    className="checks-mp"
                    type="checkbox"
                    name="isBKRefunds"
                    id="is-refunds"
                    label="Refunds - Bookkeeping Verified"
                    onChange={(e) => this.setState({ isBKRefund: e.target.checked })}
                    defaultChecked={this.props.selectedBatch.verified_refunds}
                  />
                </div>
              </div>

              <div
                style={{
                  fontSize: '13px',
                  marginTop: '8px',
                  paddingTop: '10px',
                  borderTop: '1px solid #ddd',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formBuyerName">
                      <Form.Label>Buyer's Name</Form.Label>
                      <Form.Control type="text" name="buyerName" defaultValue={this.props.selectedBatch.buyer_name} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="buyerEmail">
                      <Form.Label>Buyer's Email</Form.Label>
                      <Form.Control type="text" name="buyerEmail" defaultValue={this.props.selectedBatch.buyer_email} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Form.Group controlId="buyerPhone">
                      <Form.Label>Buyer's Phone</Form.Label>
                      <Form.Control
                        type="text"
                        name="buyerPhone"
                        defaultValue={this.props.selectedBatch.buyer_contact_number}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formBuyerName">
                      <Form.Label>Buyer's Address</Form.Label>
                      <Form.Control
                        name="buyerAddress"
                        as="textarea"
                        rows={5}
                        defaultValue={this.props.selectedBatch.buyer_address}
                      />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="buyerCity">
                      <Form.Label>Buyer's City</Form.Label>
                      <Form.Control type="text" name="buyerCity" defaultValue={this.props.selectedBatch.buyer_city} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="buyerState">
                      <Form.Label>Buyer's State</Form.Label>
                      <Form.Control type="text" name="buyerState" defaultValue={this.props.selectedBatch.buyer_state} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Form.Group controlId="buyerZIP">
                      <Form.Label>Buyer's ZIP</Form.Label>
                      <Form.Control type="text" name="buyerZIP" defaultValue={this.props.selectedBatch.buyer_zip} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Form.Check
                      style={{ fontWeight: 'bold' }}
                      type="switch"
                      name="isRequiresLiftGate"
                      id="requires-life-gate"
                      label="Lift Gate?"
                      defaultChecked={this.props.selectedBatch.requires_lift_gate === 1 ? true : false}
                    />
                  </div>
                </div>

                {/* <div
                  style={{
                    display: 'flex',
                    fontSize: '13px',
                    alignItems: 'center',
                    marginTop: '8px',
                    paddingTop: '10px',
                    borderTop: '1px solid #ddd',
                  }}
                >
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Buyer Commission</Form.Label>
                      <Form.Control
                        type="text"
                        name="buyerCommision"
                        defaultValue={this.props.selectedBatch.buyer_commission}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Seller Commission</Form.Label>
                      <Form.Control
                        type="text"
                        name="sellerCommision"
                        defaultValue={this.props.selectedBatch.seller_commission}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Total Profit</Form.Label>
                      <Form.Control
                        type="text"
                        name="totalProfit"
                        defaultValue={
                          this.props.selectedBatch.buyer_commission +
                          this.props.selectedBatch.seller_commission +
                          this.props.selectedBatch.freight_charge -
                          this.props.selectedBatch.freight_cost
                        }
                      />
                    </Form.Group>
                  </div>
                </div> */}
              </div>
            </Modal.Body>

            <Modal.Footer>
              {this.props.isUpdatingBatch === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Update
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalDetails;
