import React, { Component } from 'react';
import TopMenu from '../components/TopMenu';
import { apiBaseUrlLocal } from '../includes/AppConfig';
import { abortRequests } from '../includes/HelperDashboard';
import { Row, Col, Container, Button, Tabs, Tab, Form, Spinner, Table, Alert } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CardForm from '../components/Payments/CardForm';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import ModalMigration from '../components/Settings/ModalMigration';

const cookies = new Cookies();
const stripePromise = loadStripe(
  'pk_live_51IBKtGC7FxMC3n4vimf9s7YT4uZU8plqGBord2VUS7AtT2D75noDsFieCPocMz0HJKofyZWgwkHT44F7cZCZkiWq00hNTux3IB'
);

class Settings extends Component {
  state = {
    showAddModal: false,
    admin_data: cookies.get('admin_data'),
    statsTime: '00:00',
    isStatsDaily: false,
    isLoading: true,
    isStatsWeekly: false,
    statsEmail: '',
    isStatsMonthly: false,
    isUpdatingSettings: false,
    isUpdatingSubscription: false,
    currentPlanName: '',
    cardErrorMessage: '',
    subscriptionJSON: {},
    teamMembersCount: 0,
    newAddressSuccess: false,
    newAddressFail: false,
    showModalMigration: false,
    primary_address: {
      ship_address_name: '',
      ship_address_line1: '',
      ship_address_city: '',
      ship_address_state_code: '',
      ship_address_postal_code: '',
      ship_address_country_code: '',
    },
    addresses: [],
    showSubChoose: false,
    currentSelectedSubscriptionPlan: '1',
    showAddNewPM: false,
    updateFormInputDisabled: false,
    soundApiURL: apiBaseUrlLocal.replace('/v1', ''),
  };

  constructor() {
    super();
    this.onChangeStatsTime = this.onChangeStatsTime.bind(this);
    this.onFormSubmitSaveEmailSettings = this.onFormSubmitSaveEmailSettings.bind(this);
    this.onFormSubmitUpdatePlan = this.onFormSubmitUpdatePlan.bind(this);
    this.updateAdminData = this.updateAdminData.bind(this);
    this.updateCardErrorMessage = this.updateCardErrorMessage.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.onFormSubmitNewAddress = this.onFormSubmitNewAddress.bind(this);
    this.getAddresses = this.getAddresses.bind(this);
    this.markPrimary = this.markPrimary.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
    this.checkMigration = this.checkMigration.bind(this);
  }

  componentDidMount() {
    this.loadSettings();
    this.getAddresses();
    this.checkMigration();
  }

  checkMigration = () => {
    console.log(`Checking migrations`, this.state.admin_data);
    if (this.state.admin_data.scoutiq_email === null) this.setState({ showModalMigration: true });
  };

  onChangeStatsTime = (time) => {
    console.log(time);
    this.setState({ statsTime: time });
  };

  updateCardErrorMessage = (cardErrorMessage) => {
    this.setState({ cardErrorMessage });
  };

  postSettings = async (settings_json) => {
    this.setState({ isUpdatingSettings: true });
    const response = await fetch(apiBaseUrlLocal + 'admin/teams/settings', {
      method: 'PUT',
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        settings_json: settings_json,
      }),
    });
    this.setState({ isUpdatingSettings: false });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else return false;
  };

  postUpdatePlan = async () => {
    this.setState({ isModifyingPlan: true });
    const response = await fetch(apiBaseUrlLocal + 'admin/payments/update-subscription', {
      method: 'PUT',
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        plan_name: this.state.currentSelectedSubscriptionPlan,
      }),
    });
    this.setState({ isModifyingPlan: false });

    if (response.status === 200) {
      const subData = await response.json();
      this.setState({ subscriptionJSON: subData.data, showSubChoose: false, showAddNewPM: false });
    }
  };

  showCardForm = () => {
    return (
      <Form onSubmit={this.onFormSubmitUpdatePlan}>
        <div
        // style={{
        //   marginTop: '30px',
        //   width: '1080px',
        //   maxWidth: '100%',
        //   borderTop: '1px solid #ccc',
        //   paddingTop: '15px',
        // }}
        >
          <h5>Credit Card</h5>
          <div style={{ fontSize: '14px', paddingBottom: '5px', borderBottom: '1px solid #ddd', marginBottom: '5px' }}>
            <b>Plan Selected:</b>{' '}
            {this.state.currentSelectedSubscriptionPlan === '1'
              ? 'PalletIQ Software: $99/month + $19/month per extra user'
              : this.state.currentSelectedSubscriptionPlan === '3'
              ? 'Both:  PalletIQ -> $99/month + $19/month per extra user | Marketplace API -> $149/month'
              : 'Marketplace API: $249/month'}
            <div style={{ marginTop: '5px' }}>
              <a href="#" onClick={() => this.setState({ showSubChoose: true, showAddNewPM: false })}>
                Switch Plan
              </a>
            </div>
          </div>
          <div
            style={{
              height: '50px',
              display: this.state.cardErrorMessage === '' ? 'none' : 'block',
            }}
          >
            {this.state.cardErrorMessage !== '' && (
              <div style={{ color: '#dc3545', fontSize: '14px' }}>{this.state.cardErrorMessage}</div>
            )}
          </div>
          <div
            style={{
              height: '50px',
              fontSize: '15px',
              display: this.state.cardErrorMessage === '' ? 'block' : 'none',
            }}
          >
            {this.state.cardErrorMessage === '' &&
              this.state.admin_data.card_last4 !== '' &&
              this.state.admin_data.card_last4 !== null && (
                <div>
                  <b>Current Card: </b>{' '}
                  {'card_brand' in this.state.admin_data && this.state.admin_data.card_brand !== null
                    ? this.state.admin_data.card_brand.toUpperCase() +
                      ' ENDING IN ' +
                      this.state.admin_data.card_last4.toString()
                    : 'NO CARD'}
                </div>
              )}
          </div>
          <Elements stripe={stripePromise} style={{ width: '100%' }}>
            <CardForm
              currentSelectedSubscriptionPlan={this.state.currentSelectedSubscriptionPlan}
              style={{ width: '100%' }}
              updateCardErrorMessage={this.updateCardErrorMessage}
              isAddingCard={this.state.isAddingCard}
              admin_data={this.state.admin_data}
              updateAdminData={this.updateAdminData}
            />
          </Elements>
        </div>
      </Form>
    );
  };

  convertPlanId2Name(planId) {
    return planId === 'price_1M4s36C7FxMC3n4vVhSUPH6O'
      ? 'CashMonkey API Marketplace'
      : planId === 'price_1M4piNC7FxMC3n4vghWwjygJ'
      ? 'PalletIQ API Marketplace'
      : planId === 'price_1IsUbLC7FxMC3n4vtdIhve4Y'
      ? 'PalletIQ Dual Market'
      : 'PalletIQ Lite';
  }

  renderPlanNames() {
    let renderEle = <span style={{ color: 'red' }}> NO PLAN</span>;
    if (this.state.subscriptionJSON && this.state.subscriptionJSON.subItems) {
      renderEle = (
        <div>
          {this.state.subscriptionJSON.subItems.data.map((subItem) => (
            <div>
              {this.convertPlanId2Name(subItem.price.id)} <b>X {subItem.quantity}</b>
            </div>
          ))}
        </div>
      );
    }

    return renderEle;
  }

  getAddresses = async () => {
    const responseGetAddresses = await fetch(apiBaseUrlLocal + 'admin/addresses/list', {
      method: 'POST',
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (responseGetAddresses.status === 200) {
      const addressesJson = await responseGetAddresses.json();
      this.setState({ addresses: addressesJson.data });
      addressesJson.data.forEach((ship_address) => {
        if (ship_address.is_primary === 1) this.setState({ primary_address: ship_address });
      });
    }
  };

  markPrimary = async (user_address) => {
    const responseHttp = await fetch(apiBaseUrlLocal + 'admin/addresses/set_default', {
      method: 'POST',
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        address_id: user_address.id,
      }),
    });
    const response = await responseHttp.json();

    if ('status' in response && response.status === true) {
      this.setState({ addresses: response.data });
      response.data.forEach((ship_address) => {
        if (ship_address.is_primary === 1) this.setState({ primary_address: ship_address });
      });
    }
  };

  deleteAddress = async (user_address) => {
    confirmAlert({
      title: 'Delete Address?',
      message: 'This will permanently delete the address. Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const responseRaw = await fetch(apiBaseUrlLocal + 'admin/addresses/', {
              method: 'DELETE',
              body: new URLSearchParams({
                user_id: this.state.admin_data.admin_id,
                app_token: this.state.admin_data.token,
                address_id: user_address.id,
              }),
            });
            const response = await responseRaw.json();

            if ('status' in response && response.status === true) this.setState({ addresses: response.data });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  getSettings = async () => {
    this.setState({ isLoading: true });
    const responseGetSubscription = await fetch(apiBaseUrlLocal + 'admin/payments/get-subscription', {
      method: 'POST',
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (responseGetSubscription.status === 200) {
      const subData = await responseGetSubscription.json();

      let currentSelectedSubscriptionPlan = '1';
      if (subData.data.subItems.data.findIndex((subItem) => subItem.price.id === 'price_1M4piNC7FxMC3n4vghWwjygJ') > -1)
        currentSelectedSubscriptionPlan = '2';
      if (subData.data.subItems.data.findIndex((subItem) => subItem.price.id === 'price_1M4s36C7FxMC3n4vVhSUPH6O') > -1)
        currentSelectedSubscriptionPlan = '3';

      this.setState({ subscriptionJSON: subData.data, currentSelectedSubscriptionPlan });
    }

    const response = await fetch(apiBaseUrlLocal + 'admin/teams/get_settings', {
      method: 'POST',
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    this.setState({ isLoading: false });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else return false;
  };

  async loadSettings() {
    const settingsJsonUnparsed = await this.getSettings();
    console.log(settingsJsonUnparsed);
    if (settingsJsonUnparsed !== false && 'settings' in settingsJsonUnparsed.data) {
      const settingsJson = JSON.parse(settingsJsonUnparsed.data.settings);
      if (settingsJson !== null && settingsJson !== undefined) {
        const emailSettings = settingsJson.emailSettings;
        console.log(emailSettings);
        this.setState({
          isStatsDaily: emailSettings.isStatsDaily,
          isStatsWeekly: emailSettings.isStatsWeekly,
          isStatsMonthly: emailSettings.isStatsMonthly,
          statsEmail: emailSettings.statsEmail,
          statsTime: emailSettings.statsTime,
          teamMembersCount: settingsJsonUnparsed.data.team_member_count,
        });
      }
    }
  }

  async onFormSubmitSaveEmailSettings(event) {
    event.preventDefault();
    const statsEmail = 'statsEmail' in event.target ? event.target.statsEmail.value : '';
    const statsTime = parseInt(this.state.statsTime);
    const [isStatsDaily, isStatsWeekly, isStatsMonthly] = [
      this.state.isStatsDaily,
      this.state.isStatsWeekly,
      this.state.isStatsMonthly,
    ];
    const settingsJson = {
      emailSettings: {
        statsEmail,
        statsTime,
        isStatsDaily,
        isStatsWeekly,
        isStatsMonthly,
      },
    };
    await this.postSettings(JSON.stringify(settingsJson));
  }

  async updateSubscriptionPlan() {
    await this.postUpdatePlan();

    // window.location.reload();
  }

  async onFormSubmitUpdatePlan(event) {
    event.preventDefault();

    await this.postUpdatePlan();
    window.location.reload();
  }

  handleChange = (frequency, e) => {
    if (frequency === 'daily') this.setState({ isStatsDaily: e.target.checked });
    if (frequency === 'weekly') this.setState({ isStatsWeekly: e.target.checked });
    if (frequency === 'monthly') this.setState({ isStatsMonthly: e.target.checked });
  };

  updateAdminData = (newAdminData) => {
    this.setState({ admin_data: newAdminData }, () => {
      this.getSettings();
    });
    cookies.set('admin_data', newAdminData, { path: '/' });
  };

  cancelSubscription() {
    confirmAlert({
      title: 'Are you sure you want to cancel?',
      message: `We're sorry to see you go! If you continue your cancellation it will become effective at the end of your current billing cycle: ${new Date(
        this.state.subscriptionJSON.nextInvoiceDate * 1000
      ).toDateString()}. You can rejoin any time - all your team and scan data will be archived.`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            this.setState({ isLoading: true });
            const responseGetSubscription = await fetch(apiBaseUrlLocal + 'admin/payments/cancel-subscription', {
              method: 'POST',
              body: new URLSearchParams({
                user_id: this.state.admin_data.admin_id,
                app_token: this.state.admin_data.token,
              }),
            });
            if (responseGetSubscription.status === 200) {
              await responseGetSubscription.json();
              // this.setState({ subscriptionJSON: {} });
              this.getSettings();
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }

  async onFormSubmitNewAddress(event) {
    event.preventDefault();
    this.setState({ updateFormInputDisabled: true });
    const response = await fetch(apiBaseUrlLocal + 'admin/addresses/', {
      method: 'POST',
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        address_name: event.target.elements.addressName.value,
        address_line1: event.target.elements.addressLine1.value,
        address_city: event.target.elements.addressCity.value,
        address_state: event.target.elements.addressStateCode.value,
        address_postal_code: event.target.elements.addressPostalCode.value,
        address_country_code: event.target.elements.addressCountryCode.value,
      }),
    });
    const newAddressResponse = await response.json();

    this.setState({ updateFormInputDisabled: false });
    if (newAddressResponse.status === true) this.setState({ newAddressSuccess: true, newAddressFail: false });
    else this.setState({ newAddressSuccess: false, newAddressFail: true });
  }

  render() {
    let hours = [];
    for (let i = 0; i < 24; i += 4) hours.push(i);
    return (
      <React.Fragment>
        <TopMenu
          history={this.props.history}
          activeKey="/account-settings"
          handlenavigationroutes={(routeName) => {
            abortRequests();
            this.props.history.push(routeName);
          }}
          isReevaluate={
            'is_re_evaluate' in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
        />

        {/* <ModalMigration adminData={this.state.admin_data} showModalMigration={this.state.showModalMigration} /> */}

        <Container fluid={true} style={{ paddingBottom: '200px' }}>
          <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
              <h2>Account</h2>

              <Tabs defaultActiveKey="billing" id="account-settings-tabs" style={{ marginTop: '25px' }}>
                <Tab eventKey="billing" title="Subscription">
                  <Row>
                    <Col md={6}>
                      <div style={{ marginTop: '40px', padding: '10px', border: '1px solid #ccc' }}>
                        <div>
                          <h5>Current Subscription</h5>
                        </div>

                        <Table striped>
                          <tbody>
                            <tr>
                              <td>
                                <b>Plan Name</b>
                              </td>
                              <td>{this.state.isLoading ? 'Fetching ...' : this.renderPlanNames()}</td>
                            </tr>

                            <tr>
                              <td>
                                <b>Subscription Status</b>
                              </td>
                              <td>
                                {this.state.isLoading ? (
                                  'Fetching ...'
                                ) : 'subData' in this.state.subscriptionJSON &&
                                  'cancel_at_period_end' in this.state.subscriptionJSON.subData &&
                                  this.state.subscriptionJSON.subData.cancel_at_period_end === true ? (
                                  <span style={{ color: 'orange' }}>
                                    Will cancel on{' '}
                                    {new Date(
                                      this.state.subscriptionJSON.subData.current_period_end * 1000
                                    ).toDateString()}
                                  </span>
                                ) : 'subStatus' in this.state.subscriptionJSON &&
                                  this.state.subscriptionJSON.subStatus !== '' &&
                                  this.state.subscriptionJSON.subStatus === 'active' ? (
                                  <span style={{ color: 'green', fontWeight: 'bold' }}>ACTIVE</span>
                                ) : 'subStatus' in this.state.subscriptionJSON &&
                                  this.state.subscriptionJSON.subStatus !== '' &&
                                  this.state.subscriptionJSON.subStatus === 'trialing' ? (
                                  <span style={{ color: 'orange', fontWeight: 'bold' }}>TRIAL</span>
                                ) : 'subStatus' in this.state.subscriptionJSON &&
                                  this.state.subscriptionJSON.subStatus !== '' &&
                                  this.state.subscriptionJSON.subStatus !== 'active' ? (
                                  <span style={{ color: 'red', fontWeight: 'bold' }}>INACTIVE</span>
                                ) : (
                                  <span style={{ color: 'red' }}>NO PLAN</span>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <b>Next Invoice Date</b>
                              </td>
                              <td>
                                {this.state.isLoading ? (
                                  'Fetching ...'
                                ) : 'nextInvoiceDate' in this.state.subscriptionJSON &&
                                  this.state.subscriptionJSON.nextInvoiceDate !== '' ? (
                                  new Date(this.state.subscriptionJSON.nextInvoiceDate * 1000).toDateString()
                                ) : (
                                  <span style={{ color: 'red' }}>N/A</span>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <b>Next Invoice Amount</b>
                              </td>
                              <td>
                                {this.state.isLoading ? (
                                  'Fetching ...'
                                ) : 'nextInvoiceAmount' in this.state.subscriptionJSON &&
                                  this.state.subscriptionJSON.nextInvoiceAmount !== '' ? (
                                  '$' +
                                  this.state.subscriptionJSON.nextInvoiceAmount / 100 +
                                  ('nextInvoicePDF' in this.state.subscriptionJSON ? (
                                    <span>
                                      <a href={this.state.subscriptionJSON.nextInvoicePDF}>
                                        (Click here to download PDF)
                                      </a>
                                    </span>
                                  ) : (
                                    ''
                                  ))
                                ) : (
                                  <span style={{ color: 'red' }}>N/A</span>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={2}>
                                {this.state.isLoading === false &&
                                  this.state.subscriptionJSON.subId &&
                                  (!('subData' in this.state.subscriptionJSON) ||
                                    !('cancel_at_period_end' in this.state.subscriptionJSON.subData) ||
                                    this.state.subscriptionJSON.subData.cancel_at_period_end === false) && (
                                    <Button
                                      size="sm"
                                      variant="danger"
                                      disabled={this.state.isUpdatingSubscription}
                                      onClick={this.cancelSubscription}
                                    >
                                      {this.state.isUpdatingSubscription === true ? (
                                        <Spinner animation="border" role="status">
                                          <span className="sr-only">Loading...</span>
                                        </Spinner>
                                      ) : (
                                        'Cancel Subscription'
                                      )}
                                    </Button>
                                  )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ccc' }}>
                        {this.state.showAddNewPM ? (
                          <div>
                            <this.showCardForm />
                          </div>
                        ) : this.state.showSubChoose ? (
                          <div>
                            <div>
                              <h5>Pick a plan</h5>

                              <Form.Control
                                as="select"
                                disabled={this.state.isModifyingPlan}
                                defaultValue={this.state.currentSelectedSubscriptionPlan}
                                style={{ marginTop: '20px' }}
                                onChange={(e) => this.setState({ currentSelectedSubscriptionPlan: e.target.value })}
                              >
                                <option value="1">PalletIQ Software</option>
                                <option value="2">Marketplace API Access</option>
                                <option value="3">Both PalletIQ Subscription & Marketplace API Access</option>
                              </Form.Control>

                              <div style={{ marginTop: '10px', fontSize: '13px', color: '#ccc' }}>
                                <b>Billed at: </b>
                                {this.state.currentSelectedSubscriptionPlan === '1'
                                  ? '  $99/month + $19/month per extra user'
                                  : this.state.currentSelectedSubscriptionPlan === '3'
                                  ? '  PalletIQ: $99/month + $19/month per extra user | Marketplace API: $149/month'
                                  : '  $249/month'}
                              </div>

                              <div style={{ marginTop: '20px' }}>
                                <Button
                                  disabled={this.state.isModifyingPlan}
                                  variant="success"
                                  onClick={() => {
                                    if (this.state.admin_data.stripe_payment_id) this.updateSubscriptionPlan();
                                    else this.setState({ showAddNewPM: true });
                                  }}
                                >
                                  {this.state.isModifyingPlan ? (
                                    <Spinner animation="border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </Spinner>
                                  ) : (
                                    'Update'
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <Button variant="primary" onClick={() => this.setState({ showSubChoose: true })}>
                              {this.state.subscriptionJSON && this.state.subscriptionJSON.subId
                                ? 'Modify Subscription'
                                : 'Add New Subscription'}
                            </Button>

                            <Button
                              style={{ marginLeft: '20px' }}
                              variant="dark"
                              onClick={() => this.setState({ showAddNewPM: true })}
                            >
                              Change Card
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Tab>

                <Tab eventKey="settings" title="Email Notifications">
                  <Form inline onSubmit={this.onFormSubmitSaveEmailSettings}>
                    <div style={{ marginTop: '30px' }}>
                      <h4 style={{ marginBottom: '20px' }}>Email Notifications</h4>
                      <p>Enable to receive daily, weekly or monthly scan statistics on email.</p>

                      <div>
                        <Form.Check
                          inline
                          type="switch"
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          name="isDailyStats"
                          id="daily-stats"
                          checked={this.state.isStatsDaily}
                          onChange={(e) => this.handleChange('daily', e)}
                          label="Every Day?"
                        />
                        <Form.Check
                          inline
                          type="switch"
                          name="isWeeklyStats"
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          id="weekly-stats"
                          checked={this.state.isStatsWeekly}
                          onChange={(e) => this.handleChange('weekly', e)}
                          label="Every Monday?"
                        />
                        <Form.Check
                          inline
                          type="switch"
                          name="isMonthlyStats"
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          id="monthly-stats"
                          checked={this.state.isStatsMonthly}
                          onChange={(e) => this.handleChange('monthly', e)}
                          label="On the first of every month?"
                        />
                      </div>

                      <div style={{ marginTop: '20px' }}>
                        <span style={{ marginRight: '15px' }}>Time: </span>
                        <Form.Control
                          as="select"
                          name="statsTime"
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          value={this.state.statsTime}
                          onChange={(e) => this.setState({ statsTime: e.target.value })}
                        >
                          {hours.map((hour) => {
                            return <option value={hour}>{hour + ':00'}</option>;
                          })}
                        </Form.Control>
                        <span style={{ marginLeft: '15px' }}>(PST)</span>
                      </div>

                      <div style={{ marginTop: '20px' }}>
                        <span style={{ marginRight: '15px' }}>Email Address: </span>
                        <Form.Control
                          inline
                          required
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          type="text"
                          name="statsEmail"
                          value={this.state.statsEmail}
                          onChange={(e) => this.setState({ statsEmail: e.target.value })}
                          style={{ width: '300px' }}
                        />
                      </div>

                      <div style={{ marginTop: '31px' }}>
                        <Button
                          disabled={this.state.isLoading || this.state.isUpdatingSettings}
                          variant="primary"
                          type="submit"
                        >
                          {this.state.isUpdatingSettings === true ? (
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          ) : (
                            'Save Changes'
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Tab>

                <Tab eventKey="addresses" title="Addresses">
                  {/* <h5>Current Address</h5>
                    <h5>Add new Address</h5> */}
                  <Form onSubmit={this.onFormSubmitAddAddress}>
                    <Row>
                      <Col md={6}>
                        <div style={{ marginTop: '30px' }}>
                          <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
                            <h4>Current Active Address</h4>
                          </div>

                          <Row style={{ marginTop: '15px', lineHeight: '40px' }}>
                            <Col md={12}>
                              <strong>Address Name: </strong>
                              {this.state.primary_address.ship_address_name || 'N/A'}
                            </Col>
                            <Col md={12}>
                              <strong>Address Line 1: </strong>
                              {this.state.primary_address.ship_address_line1 || 'N/A'}
                            </Col>
                            <Col md={6}>
                              <strong>City: </strong>
                              {this.state.primary_address.ship_address_city || 'N/A'}
                            </Col>
                            <Col md={6}>
                              <strong>State Code: </strong>
                              {this.state.primary_address.ship_address_state_code || 'N/A'}
                            </Col>
                            <Col md={6}>
                              <strong>Postal Code: </strong>
                              {this.state.primary_address.ship_address_postal_code || 'N/A'}
                            </Col>
                            <Col md={6}>
                              <strong>Country Code: </strong>
                              {this.state.primary_address.ship_address_country_code || 'N/A'}
                            </Col>
                          </Row>

                          <Row style={{ marginTop: '25px' }}>
                            <Col md={12}>
                              <h5>Other Addresses</h5>
                              <Table style={{ fontSize: '14px' }}>
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.addresses.map((user_address, index) => (
                                    <tr>
                                      <td>{user_address.ship_address_name}</td>
                                      <td>
                                        <span
                                          onClick={this.markPrimary.bind(this, user_address)}
                                          className="spanClickable"
                                        >
                                          Mark Primary
                                        </span>
                                        {user_address.is_primary !== 1 && (
                                          <span
                                            onClick={this.deleteAddress.bind(this, user_address)}
                                            className="spanClickable"
                                          >
                                            Delete
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div style={{ marginTop: '30px' }}>
                          <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
                            <h4>Add New Address</h4>
                          </div>
                          {this.state.newAddressSuccess && (
                            <Alert
                              style={{ marginTop: '10px' }}
                              variant="success"
                              onClose={() => this.setState({ newAddressSuccess: false })}
                              dismissible
                            >
                              <Alert.Heading>Success!</Alert.Heading>
                              <p>Address added successfully.</p>
                            </Alert>
                          )}
                          {this.state.newAddressFail && (
                            <Alert
                              style={{ marginTop: '10px' }}
                              variant="danger"
                              onClose={() => this.setState({ newAddressFail: false })}
                              dismissible
                            >
                              <Alert.Heading>Failed!</Alert.Heading>
                              <p>Address could not be added.</p>
                            </Alert>
                          )}
                        </div>

                        <Form onSubmit={this.onFormSubmitNewAddress}>
                          <Row style={{ marginTop: '15px' }}>
                            <Col md={6}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Address Name</Form.Label>
                                <Form.Control name="addressName" disabled={this.state.updateFormInputDisabled} />
                                <Form.Text className="text-muted">E.g. Home, Office, Warehouse, etc.</Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Address Line 1</Form.Label>
                                <Form.Control name="addressLine1" disabled={this.state.updateFormInputDisabled} />
                                <Form.Text className="text-muted">Includes House & Street #.</Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>City</Form.Label>
                                <Form.Control name="addressCity" disabled={this.state.updateFormInputDisabled} />
                                <Form.Text className="text-muted">E.g. Aurora.</Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>State Code</Form.Label>
                                <Form.Control name="addressStateCode" disabled={this.state.updateFormInputDisabled} />
                                <Form.Text className="text-muted">E.g. CO, LA, etc.</Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control name="addressPostalCode" disabled={this.state.updateFormInputDisabled} />
                                <Form.Text className="text-muted">E.g. 80016 for CO</Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>Country Code</Form.Label>
                                <Form.Control name="addressCountryCode" disabled={this.state.updateFormInputDisabled} />
                                <Form.Text className="text-muted">E.g. US, UK, CA.</Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: '15px' }}>
                            <Col md={12}>
                              <Button variant="primary" type="submit">
                                Update
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Form>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Settings;
