import React, { useMemo, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Spinner, Form } from 'react-bootstrap';
import { apiBaseUrlLocal } from '../../includes/AppConfig';
import useResponsiveFontSize from './useResponsiveFontSize';
import '../../assets/style/CardSelectionStyle.css';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardForm = ({ admin_data, currentSelectedSubscriptionPlan, updateAdminData, updateCardErrorMessage }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [isUpdatingCard, setIsUpdatingCard] = useState(false);

  const handleUpdatePayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsUpdatingCard(true);
    updateCardErrorMessage('');
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    console.log(payload);

    if ('error' in payload) {
      updateCardErrorMessage(payload.error.message);
      setIsUpdatingCard(false);
    } else {
      const cardLast4 = payload.paymentMethod.card.last4;
      const paymentId = payload.paymentMethod.id;
      const cardBrand = payload.paymentMethod.card.brand;

      admin_data.stripe_payment_id = paymentId;
      admin_data.card_last4 = cardLast4;
      admin_data.card_brand = cardBrand;

      const response = await fetch(apiBaseUrlLocal + 'admin/payments/payment_id', {
        method: 'PUT',
        body: new URLSearchParams({
          user_id: admin_data.admin_id,
          app_token: admin_data.token,
          payment_id: paymentId,
          card_last4: cardLast4,
          card_brand: cardBrand,
        }),
      });

      // Adding or Updating subscription to the user
      await fetch(apiBaseUrlLocal + 'admin/payments/update-subscription', {
        method: 'PUT',
        body: new URLSearchParams({
          user_id: admin_data.admin_id,
          app_token: admin_data.token,
          plan_name: currentSelectedSubscriptionPlan,
        }),
      });

      setIsUpdatingCard(false);

      if (response.status === 200) {
        const data = await response.json();
        admin_data.stripe_customer_id = data.stripe_customer_id;
        updateAdminData(admin_data);
      } else console.log(response);
    }
  };

  return (
    <>
      <CardElement options={options} />

      <Button variant="primary" style={{ marginTop: '20px' }} onClick={handleUpdatePayment}>
        {isUpdatingCard === true ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          'Update Card'
        )}
      </Button>
    </>
  );
};

export default CardForm;
