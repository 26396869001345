import React, { Component } from 'react';
import { Container, Row, Col, Button, Spinner, InputGroup, FormControl, Form, Table } from 'react-bootstrap';
import { reconfigTB } from '../../../includes/HelperTriggerBatches';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ColorTextPicker from '../../Others/ColorTextPicker';
import ColorTextPickerPlain from '../../Others/ColorTextPickerPlain';

class RITrigger extends Component {
  state = {
    isTriggerEnabled: false,
    config_wholesale: [],
    targetDiff: 0,
    thisTabIndex: -1,
    reconfigInProgress: false,
    customText: '',
    customColor: '',
    minPrice: 0,
    riskAdjustmentPer: 100,
  };

  constructor(props) {
    super(props);
    this.handleReConfig = this.handleReConfig.bind(this);
    this.toggleCurrent = this.toggleCurrent.bind(this);
  }

  componentDidMount() {
    /**
     * This is what executes before everything when the page loads.
     * What we're doing here:
     * 1. calling API to load/set triggers
     */
    this.setState({ triggerBatchId: this.props.triggerBatchId });

    if ('conf_wholesale' in this.props.batchConfiguration && this.props.batchConfiguration.conf_wholesale !== null) {
      const config_wholesale = JSON.parse(this.props.batchConfiguration.conf_wholesale);
      this.setState({ config_wholesale });

      const index = config_wholesale.findIndex(function(module) {
        return module.module_name === 'RI';
      });
      this.setState({ thisTabIndex: index });

      const current_config = config_wholesale[index];

      if (current_config?.enabled) {
        this.setState({
          isTriggerEnabled: current_config.enabled,
          targetDiff: current_config.target_diff,
          customText: 'text' in current_config ? current_config.text : '',
          customColor: 'color' in current_config ? current_config.color : '',
          minPrice: 'min_price' in current_config ? current_config.min_price : 0,
          timeoutSeconds: 'timeout' in current_config ? current_config.timeout : 1200,
          riskAdjustmentPer: 'risk_adjustment_per' in current_config ? current_config.risk_adjustment_per : 100,
        });
      }
    }
  }

  async handleReConfig(color, text) {
    let config_wholesale_clone = [...this.state.config_wholesale];
    const index = config_wholesale_clone.findIndex(function(module) {
      return module.module_name === 'RI';
    });
    this.setState({ reconfigInProgress: true });
    const targetDiff = this.state.targetDiff;
    if (targetDiff >= 0) {
      config_wholesale_clone[index].enabled = true;
      config_wholesale_clone[index].target_diff = parseFloat(targetDiff);
      config_wholesale_clone[index].min_price = parseFloat(this.state.minPrice);
      config_wholesale_clone[index].timeout = parseFloat(this.state.timeoutSeconds);
      config_wholesale_clone[index].risk_adjustment_per = parseInt(this.state.riskAdjustmentPer);
      if (undefined !== color && undefined !== text) {
        config_wholesale_clone[index].color = color;
        config_wholesale_clone[index].text = text;
      }
      this.setState({ isTriggerEnabled: true });
    } else {
      config_wholesale_clone[index].enabled = false;
      this.setState({ isTriggerEnabled: false });
    }
    this.props.updateTBConfig('conf_wholesale', config_wholesale_clone);
    await reconfigTB(
      this.props.admin_data,
      this.props.triggerBatchId,
      'conf_wholesale',
      JSON.stringify(config_wholesale_clone)
    );
    this.setState({ reconfigInProgress: false });
  }

  async toggleCurrent(e) {
    this.setState({ isTriggerEnabled: e.target.checked, reconfigInProgress: true });

    let config_wholesale_clone = [...this.state.config_wholesale];
    const index = config_wholesale_clone.findIndex(function(module) {
      return module.module_name === 'RI';
    });
    if (index > -1)
      config_wholesale_clone[index].enabled = e.target.checked;
    else
      config_wholesale_clone.push({
        enabled: e.target.checked,
        module_name: "RI",
        target_diff: 0,
      })
    this.setState({ isTriggerEnabled: e.target.checked, config_wholesale: config_wholesale_clone });

    this.props.updateTBConfig('conf_wholesale', config_wholesale_clone);

    await reconfigTB(
      this.props.admin_data,
      this.props.triggerBatchId,
      'conf_wholesale',
      JSON.stringify(config_wholesale_clone)
    );
    this.setState({ reconfigInProgress: false });
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid={true} style={{ padding: '0' }}>
          <Row style={{ marginTop: '10px', paddingBottom: '15px' }}>
            <Col md={12}>
              <table style={{ width: 'auto', marginBottom: '40px' }}>
                <tbody>
                  <tr>
                    <td style={{ padding: '10px 10px 10px 0' }}>
                      <div className="mod-setting-name">Enable Restricted Inventory?</div>
                      <div className="mod-setting-desc">
                        Toggle ON to pull Restricted Inventory (
                        <a href="https://restrictedinventory.com">restrictedinventory.com</a>) prices and include to
                        routing decision.
                      </div>
                    </td>
                    <td style={{ padding: '10px 0 10px 20px' }}>
                      <Form.Check
                        onChange={this.toggleCurrent}
                        checked={this.state.isTriggerEnabled}
                        type="switch"
                        disabled={this.state.reconfigInProgress}
                        id="enable-trigger"
                        label={this.state.isTriggerEnabled ? 'YES' : 'NO'}
                      />
                    </td>
                  </tr>

                  {this.state.isTriggerEnabled === true && (
                    <React.Fragment>
                      {this.state.thisTabIndex > 0 && (
                        <tr>
                          <td style={{ padding: '10px 10px 10px 0' }}>
                            <div className="mod-setting-name">Price Bump</div>
                            <div className="mod-setting-desc"></div>
                          </td>
                          <td style={{ padding: '10px 0 10px 20px' }}>
                            <InputGroup id="ig_asin" style={{ width: '250px' }}>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                value={this.state.targetDiff}
                                onChange={(e) => {
                                  this.setState({ targetDiff: e.target.value });
                                }}
                                disabled={this.state.reconfigInProgress}
                                autoComplete="off"
                                id="targetDiffInput"
                                placeholder="Difference"
                                type="number"
                                aria-describedby="basic-addon1"
                              />
                              <InputGroup.Append id="btn_asin_search">
                                <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                                  {this.state.reconfigInProgress === true ? (
                                    <Spinner style={{ width: '20px', height: '20px' }} animation="border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </Spinner>
                                  ) : (
                                    <span>Save</span>
                                  )}
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td style={{ padding: '10px 10px 10px 0' }}>
                          <div className="mod-setting-name">Custom Display Color & Text</div>
                          <div className="mod-setting-desc"></div>
                        </td>
                        <td style={{ padding: '10px 0 10px 20px' }}>
                          <ColorTextPickerPlain
                            customColor={this.state.customColor}
                            customText={this.state.customText}
                            updateCustomTextColor={async (color, text) => {
                              await this.handleReConfig(color, text);
                            }}
                            updatingCustomTextColor={this.state.reconfigInProgress}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ padding: '10px 10px 10px 0' }}>
                          <div className="mod-setting-name">Accept only if price greater than</div>
                          <div className="mod-setting-desc"></div>
                        </td>
                        <td style={{ padding: '10px 0 10px 20px' }}>
                          <InputGroup id="ig_asin" style={{ width: '250px' }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              style={{ width: '50px' }}
                              value={this.state.minPrice}
                              onChange={(e) => {
                                this.setState({ minPrice: e.target.value });
                              }}
                              disabled={this.state.reconfigInProgress}
                              autoComplete="off"
                              id="minPriceInput"
                              placeholder="Min"
                              type="number"
                              aria-describedby="basic-addon1"
                            />
                            <InputGroup.Append id="btn_asin_search">
                              <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                                {this.state.reconfigInProgress === true ? (
                                  <Spinner style={{ width: '20px', height: '20px' }} animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                ) : (
                                  <span>Save</span>
                                )}
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ padding: '10px 10px 10px 0' }}>
                          <div className="mod-setting-name">
                            Risk Adjustment{' '}
                            <span className="mod-setting-tag-new">
                              <sup>(NEW)</sup>
                            </span>
                          </div>
                          <div className="mod-setting-desc">
                            Display vendor prices at X % of the quoted Restricted Inventory Price
                          </div>
                        </td>
                        <td style={{ padding: '10px 0 10px 20px' }}>
                          <InputGroup id="ig_asin" style={{ width: '250px' }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              step="any"
                              value={this.state.riskAdjustmentPer}
                              onChange={(e) => {
                                this.setState({ riskAdjustmentPer: e.target.value });
                              }}
                              disabled={this.state.reconfigInProgress}
                              autoComplete="off"
                              id="riskAdjustmentPer"
                              placeholder="Enter a number"
                              type="number"
                              aria-describedby="basic-addon1"
                            />
                            <InputGroup.Append id="btn_asin_search">
                              <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                                {this.state.reconfigInProgress === true ? (
                                  <Spinner style={{ width: '20px', height: '20px' }} animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                ) : (
                                  <span>Save</span>
                                )}
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default RITrigger;
