import React, { Component } from 'react';
import TopMenu from '../components/TopMenu';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { apiBaseUrlLocal } from '../includes/AppConfig';
import { exportScans } from '../includes/HelperScans';
import Cookies from 'universal-cookie';
import { getSources, getUsers } from '../includes/HelperDashboard';

import ScansExport from '../components/Others/ScansExport';

const cookies = new Cookies();

const $ = require('jquery');
$.DataTable = require('datatables.net-responsive-dt');

class Scans extends Component {
  state = {
    tableData: [],
    showExportModal: false,
    isExportingCSV: false,
    sourcesData: [],
    users_list: [],
    isLoadingSources: false,
    isLoadingUsers: false,
    admin_data: cookies.get('admin_data'),
  };

  constructor() {
    super();
    this.onFormSubmitExportCSV = this.onFormSubmitExportCSV.bind(this);
  }

  async onFormSubmitExportCSV(startDate, endDate, checkedWinners, selectedSources, selectedUsers) {
    this.setState({ isExportingCSV: true });
    const resp = await exportScans(this.state.admin_data, {
      startDate,
      endDate,
      checkedWinners,
      selectedSources,
      selectedUsers,
    });
    this.setState({ isExportingCSV: false });

    if (resp !== false && 'status' in resp && resp.status === true) {
      let csvContent = 'data:text/csv;charset=utf-8,';

      // csvContent += "ASIN, SKU, QUANTITY, TITLE, CONDITION, CONDITION_NOTES, BUY_COST, SELF_WEAR, SOURCE, LISTING_PROFILE";

      csvContent +=
        'ASIN,SCANNED BY,TITLE,ESCORE,SALES RANK,SOURCE,PRODUCT GROUP,FBA DECISION,MF DECISION,MP DECISION,LOWEST USED, USED BUY BOX, LOWEST NEW, NEW BUY BOX, AMAZON PRICE, FBA TARGET PRICE, FBA EST PROFIT, MF TARGET PRICE, MF EST PROFIT, MP PRICE, SBYB PRICE, ZIFFIT PRICE, BS PRICE, ROUTED TO, SHIPMENT NAME, SCANNED ON\r\n';

      resp.data.forEach(function (rowArray) {
        const scannedDate = new Date(rowArray.scanned_on);
        const yearScanned = scannedDate.getFullYear();
        let monthScanned = (1 + scannedDate.getMonth()).toString();
        monthScanned = monthScanned.length > 1 ? monthScanned : '0' + monthScanned;

        let dayScanned = scannedDate.getDate().toString();
        dayScanned = dayScanned.length > 1 ? dayScanned : '0' + dayScanned;

        let row =
          '="' +
          rowArray.asin +
          '","' +
          (rowArray.first_name + ' ' + rowArray.last_name) +
          '","' +
          rowArray.title.replace(/[\W_]+/g, ' ') +
          '",' +
          rowArray.escore +
          ',' +
          rowArray.sales_rank +
          ',' +
          rowArray.source +
          ',' +
          rowArray.product_group +
          ',' +
          (rowArray.fba_decision > 0 ? 1 : 0) +
          ',' +
          (rowArray.mf_decision > 0 ? 1 : 0) +
          ',' +
          (rowArray.mp_decision > 0 ? 1 : 0) +
          ',' +
          rowArray.lowest_used_price +
          ',' +
          rowArray.used_buy_box +
          ',' +
          rowArray.lowest_new_price +
          ',' +
          rowArray.new_buy_box +
          ',' +
          rowArray.amazon_price +
          ',' +
          rowArray.fba_target_price +
          ',' +
          rowArray.fba_estimated_profit +
          ',' +
          rowArray.mf_target_price +
          ',' +
          rowArray.mf_estimated_profit +
          ',' +
          rowArray.mp_price +
          ',' +
          rowArray.sbyb_price +
          ',' +
          rowArray.ziffit_price +
          ',' +
          rowArray.bs_price +
          ',' +
          (rowArray.module_winner === '' ? 'REJECTED' : rowArray.module_winner + ' - ' + rowArray.hl_winner) +
          ',' +
          rowArray.shipment_name +
          ',' +
          monthScanned +
          '-' +
          dayScanned +
          '-' +
          yearScanned;

        csvContent += row + '\r\n';
      });

      var encodedUri = encodeURI(csvContent);
      const fixedEncodedURI = encodedUri.replaceAll('#', '%23');
      var link = document.createElement('a');
      link.setAttribute('href', fixedEncodedURI);
      link.setAttribute('download', `scans.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".

      //console.log(data);
    }
  }

  downloadCSV(csv, filename) {
    var csvFile;
    var downloadLink;

    // CSV file
    csvFile = new Blob([csv], { type: 'text/csv' });

    // Download link
    downloadLink = document.createElement('a');

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = 'none';

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
  }

  exportTableToCSV(filename) {
    console.log(`Exporting`);
    var csv = [];
    var rows = document.querySelectorAll('table tr');

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll('td, th');

      for (var j = 0; j < cols.length; j++) {
        if (j === 1) continue;
        row.push(cols[j].innerText.replaceAll(',', ''));
      }

      csv.push(row.join(','));
    }

    // Download CSV file
    this.downloadCSV(csv.join('\n'), filename);
  }

  componentDidMount() {
    this.setState({ isLoadingSources: true });
    getSources(this.state.admin_data, (response) => {
      const sourcesData = response.data.map((sd) => {
        return { value: sd.source, label: sd.source };
      });
      this.setState({ sourcesData, isLoadingSources: false });
    });

    getUsers(this.state.admin_data, (response) => {
      if (response !== false) {
        const users_list = response.data.map((ul) => {
          return { value: ul.id, label: ul.id + ' - ' + ul.first_name + ' ' + ul.last_name };
        });
        this.setState({ users_list, isLoadingUsers: false });
      }
    });

    this.$el = $(this.el);
    const user_token = this.state.admin_data.token;

    this.$el.DataTable({
      serverSide: true,
      ordering: true,
      searching: true,
      searchDelay: 2500,
      language: {
        processing: "<div style='padding-top:100px;'><i class='fa fa-refresh'> Loading</i></div>",
      },
      processing: true,
      responsive: true,
      pageLength: 50,
      order: [[0, 'desc']],
      ajax: {
        url: apiBaseUrlLocal + 'admin/scans/list',
        data: function (d) {
          d.token = user_token;
        },
      },
      columnDefs: [
        {
          render: function (data, type, row) {
            return row[0];
          },
          title: '#',
          targets: 0,
        },
        {
          render: function (data, type, row) {
            return row[25];
          },
          title: 'User ID',
          targets: 1,
        },
        {
          render: function (data, type, row) {
            return row[26];
          },
          title: 'User Name',
          targets: 2,
        },
        {
          render: function (data, type, row) {
            return row[1];
          },
          searchable: false,
          title: 'ASIN',
          targets: 3,
        },
        {
          render: function (data, type, row) {
            return row[22] === ''
              ? "<span style='color:red;'>REJECT</span>"
              : row[22] === 'AMAZON' || row[22] === 'WHOLESALE'
              ? row[20]
              : row[22];
          },
          searchable: true,
          title: 'WINNER',
          targets: 4,
        },
        {
          render: function (data, type, row) {
            return row[21];
          },
          title: 'SCANNED',
          searchable: false,
          targets: 5,
        },
        {
          render: function (data, type, row) {
            return row[2].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          },
          searchable: false,
          title: 'SALESRANK',
          targets: 6,
        },
        {
          render: function (data, type, row) {
            return row[3] !== null ? row[3] : 0;
          },
          title: 'eSCORE',
          searchable: false,
          targets: 7,
        },
        {
          render: function (data, type, row) {
            return row[4] !== null ? row[4] : 0;
          },
          title: 'LowestNew',
          searchable: false,
          targets: 8,
        },
        {
          render: function (data, type, row) {
            return row[5] !== null ? row[5] : 0;
          },
          title: 'LowestUsed',
          searchable: false,
          targets: 9,
        },
        {
          render: function (data, type, row) {
            return row[6] !== null ? row[6] : 0;
          },
          title: 'NewBB',
          searchable: false,
          targets: 10,
        },
        {
          render: function (data, type, row) {
            return row[7] !== null ? row[7] : 0;
          },
          title: 'UsedBB',
          searchable: false,
          targets: 11,
        },
        {
          render: function (data, type, row) {
            return row[8] !== null ? row[8] : 0;
          },
          title: 'Amazon',
          searchable: false,
          targets: 12,
        },
        {
          render: function (data, type, row) {
            return row[9] !== null ? row[9] : 0;
          },
          title: 'FBA Target',
          searchable: false,
          targets: 13,
        },
        {
          render: function (data, type, row) {
            return row[10] !== null ? row[10] : 0;
          },
          title: 'FBA Est',
          searchable: false,
          targets: 14,
        },
        {
          render: function (data, type, row) {
            return row[13] === 1 ? 'YES' : 'NO';
          },
          title: 'FBA Decision',
          searchable: false,
          targets: 15,
        },
        {
          render: function (data, type, row) {
            return row[11] !== null ? row[11] : 0;
          },
          title: 'MF Target',
          searchable: false,
          targets: 16,
        },
        {
          render: function (data, type, row) {
            return row[12] !== null ? row[12] : 0;
          },
          title: 'MF Est',
          searchable: false,
          targets: 17,
        },
        {
          render: function (data, type, row) {
            return row[14] === 1 ? 'YES' : 'NO';
          },
          title: 'MF Decision',
          searchable: false,
          targets: 18,
        },
        {
          render: function (data, type, row) {
            return row[15];
          },
          title: 'SBYB',
          searchable: false,
          targets: 19,
        },
        {
          render: function (data, type, row) {
            return row[16];
          },
          title: 'RMS',
          searchable: false,
          targets: 20,
        },
        {
          render: function (data, type, row) {
            return row[17];
          },
          title: 'BS',
          searchable: false,
          targets: 21,
        },
        {
          render: function (data, type, row) {
            return row[18];
          },
          title: 'SB',
          searchable: false,
          targets: 22,
        },
        {
          render: function (data, type, row) {
            return row[20];
          },
          title: 'ZIFFIT',
          searchable: false,
          targets: 23,
        },
        {
          render: function (data, type, row) {
            return row[23];
          },
          title: 'SHIPMENT',
          searchable: true,
          targets: 24,
        },
        {
          render: function (data, type, row) {
            return row[24];
          },
          title: 'TITLE',
          searchable: true,
          targets: 25,
        },
      ],

      scroller: {
        loadingIndicator: true,
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          history={this.props.history}
          userData={this.state.admin_data}
          activeKey="/scans"
          isReevaluate={
            'is_re_evaluate' in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
        />

        <ScansExport
          sourcesData={this.state.sourcesData}
          users_list={this.state.users_list}
          isLoadingSources={this.state.isLoadingSources}
          showExportModal={this.state.showExportModal}
          isExportingCSV={this.state.isExportingCSV}
          // onChangeAudioFile={this.onChangeAudioFile}
          handleClose={() => this.setState({ showExportModal: false })}
          onFormSubmitExportCSV={this.onFormSubmitExportCSV}
        />

        <Container fluid={true}>
          <div style={{ marginTop: '30px' }}>
            <Button
              variant="primary"
              onClick={() => {
                // this.exportTableToCSV("hello.csv");
                this.setState({ showExportModal: true });
              }}
            >
              Export CSV
            </Button>
          </div>
          <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
              <table className="display responsive nowrap" width="100%" ref={(el) => (this.el = el)}>
                <thead></thead>
                <tbody></tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Scans;
