import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { abortRequests } from "../includes/HelperDashboard";
import { Row, Col, Container } from "react-bootstrap";
import Cookies from "universal-cookie";

const cookies = new Cookies();

class UserManual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin_data: cookies.get("admin_data"),
    };
  }
  render() {
    return (
      <React.Fragment>
        <TopMenu
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          handlenavigationroutes={(routeName) => {
            abortRequests();
            this.props.history.push(routeName);
          }}
          adminData={this.state.admin_data}
          fetchStats={this.fetchStats}
          history={this.props.history}
          sources={this.state.sources}
          activeKey="/"
        />

        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <iframe
                  frameBorder="0"
                  style={{ width: "800px", height: "10000px", textAlign: "center" }}
                  src="https://docs.google.com/document/d/e/2PACX-1vQkk0GmYnf62LO28VZyFYsGx4nrwQqiBPFe3PE57dBLbgMUueQLbja_hpzv0gK7DQ/pub?embedded=true"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default UserManual;
