import { apiBaseUrlLocal } from './AppConfig';

/**
 * File has 3 type of exports:
 * Category 1. API related functions.
 * Category 2. Helper functions.
 * Cateogry 3. Constant Variables.
 */

/**
 * Category 1: API related functions
 */
export const loadTriggers = async (admin_data, triggerBatchId, triggerType) => {
  const response = await fetch(
    apiBaseUrlLocal + 'admin/triggers/list?type=' + triggerType + '&batch_id=' + triggerBatchId,
    {
      method: 'POST',
      body: new URLSearchParams({
        user_id: admin_data.admin_id,
        app_token: admin_data.token,
      }),
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const updateHitList = async (admin_data, triggerBatchId, triggerJson) => {
  const response = await fetch(apiBaseUrlLocal + 'hitlist/update?batch_id=' + triggerBatchId, {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      trigger_json: JSON.stringify(triggerJson),
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const getHitList = async (admin_data, triggerBatchId) => {
  const response = await fetch(apiBaseUrlLocal + 'hitlist/fetch?batch_id=' + triggerBatchId, {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const updateTrigger = async (admin_data, triggerBatchId, triggerId, triggerName, triggerJson, triggerType) => {
  const response = await fetch(
    apiBaseUrlLocal + 'admin/triggers/' + triggerId + '?type=' + triggerType + '&batch_id=' + triggerBatchId,
    {
      method: 'PUT',
      body: new URLSearchParams({
        user_id: admin_data.admin_id,
        app_token: admin_data.token,
        trigger_id: triggerId,
        trigger_name: triggerName,
        trigger_json: JSON.stringify(triggerJson),
      }),
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const deleteTrigger = async (admin_data, triggerBatchId, triggerId, triggerType) => {
  const response = await fetch(
    apiBaseUrlLocal + 'admin/triggers/' + triggerId + '?type=' + triggerType + '&batch_id=' + triggerBatchId,
    {
      method: 'DELETE',
      body: new URLSearchParams({
        user_id: admin_data.admin_id,
        app_token: admin_data.token,
      }),
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const addTrigger = async (admin_data, triggerBatchId, triggerName, triggerJson, triggerType) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/triggers?type=' + triggerType + '&batch_id=' + triggerBatchId, {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      trigger_name: triggerName,
      trigger_json: JSON.stringify(triggerJson),
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const addTriggerBatch = async (admin_data, triggerBatchName, teamId) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/tbatches/', {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      trigger_batch_name: triggerBatchName,
      teamId: teamId,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const delTriggerBatch = async (admin_data, triggerBatchId) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/tbatches/' + triggerBatchId, {
    method: 'DELETE',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const resetTriggerBatch = async (admin_data, triggerBatchId, type) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/tbatches/reset/' + triggerBatchId, {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      triggers_type: type,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const resTriggerBatch = async (admin_data, triggerBatchId) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/tbatches/reset/' + triggerBatchId, {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const duplicateTriggerBatch = async (admin_data, triggerBatchName, triggerBatchId, teamId) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/tbatches/duplicate/' + triggerBatchId, {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      batch_name: triggerBatchName,
      teamId: teamId,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const getTriggerBatches = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/tbatches/list', {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  return await response.json();
};

/**
 * Category 2. Helper Functions.
 */
export const eventToTriggerJson = (event) => {
  const slotKey = 'newSlot' in event.target.elements ? 'new_slot' : 'used_slot';
  const slotValue =
    'newSlot' in event.target.elements ? event.target.elements.newSlot.value : event.target.elements.usedSlot.value;

  const isBBCompare = event.target.elements.isBBCompare.checked;
  const isAlwaysReject = event.target.elements.isAlwaysReject.checked;
  const isCeiling1Options = event.target.elements.isCeiling1Enabled.checked;
  const isCeiling2Options = event.target.elements.isCeiling2Enabled.checked;
  const isCeiling3Options = event.target.elements.isCeiling3Enabled.checked;
  const isSkipEscore = event.target.elements.isSkipEscore.checked;
  const isCeilingKeepaOptions =
    'isCeilingKeepaEnabled' in event.target.elements ? event.target.elements.isCeilingKeepaEnabled.checked : false;
  const isMMEnabled = 'isMMEnabled' in event.target.elements ? event.target.elements.isMMEnabled.checked : false;

  const minEscore = event.target.elements.minEscore.value;
  const maxEscore = event.target.elements.maxEscore.value;
  const minRank = event.target.elements.minRank.value;
  const maxRank = event.target.elements.maxRank.value;
  const fbaSlot = event.target.elements.fbaSlot.value;
  const amzOffPer = event.target.elements.amzOffPer.value;
  const targetProfit = event.target.elements.targetProfit.value;
  const ceiling1Options = isCeiling1Options ? event.target.elements.ceiling1Options.value : '';
  const ceiling1Discount = isCeiling1Options ? event.target.elements.ceiling1Discount.value : 0;

  const ceiling2Options = isCeiling2Options ? event.target.elements.ceiling2Options.value : '';
  const ceiling2BumpUpDollars = isCeiling2Options ? event.target.elements.ceiling2BumpUpDollars.value : 0;
  const ceiling2BumpUpPercentage = isCeiling2Options ? event.target.elements.ceiling2BumpUpPercentage.value : 0;

  const ceiling3Options = isCeiling3Options ? event.target.elements.ceiling3Options.value : '';
  const ceiling3BumpUpDollars = isCeiling3Options ? event.target.elements.ceiling3BumpUpDollars.value : 0;
  const ceiling3BumpUpPercentage = isCeiling3Options ? event.target.elements.ceiling3BumpUpPercentage.value : 0;

  const ceilingKeepaOptions = isCeilingKeepaOptions ? event.target.elements.ceilingKeepaOptions.value : '';
  const ceilingKeepaBumpUpDollars = isCeilingKeepaOptions ? event.target.elements.ceilingKeepaBumpUpDollars.value : 0;
  const ceilingKeepaBumpUpPercentage = isCeilingKeepaOptions
    ? event.target.elements.ceilingKeepaBumpUpPercentage.value
    : 0;

  const mmMultiplier = isMMEnabled ? event.target.elements.mm_option.value : 1;

  let triggerJson = {
    min_escore: minEscore,
    max_escore: maxEscore,
    min_rank: minRank,
    max_rank: maxRank,
    fba_slot: fbaSlot,
    bb_compare: isBBCompare,
    always_reject: isAlwaysReject,
    amz_off_per: amzOffPer,
    is_skip_escore: isSkipEscore,
    target_profit: targetProfit,
    ceiling_1_option: ceiling1Options,
    ceiling_1_discount: ceiling1Discount,
    ceiling_2_option: ceiling2Options,
    ceiling_2_bump_up_dollars: ceiling2BumpUpDollars,
    ceiling_2_bump_up_percetange: ceiling2BumpUpPercentage,
    ceiling_3_option: ceiling3Options,
    ceiling_3_bump_up_dollars: ceiling3BumpUpDollars,
    ceiling_3_bump_up_percentage: ceiling3BumpUpPercentage,
    ceiling_keepa_option: ceilingKeepaOptions,
    ceiling_keepa_bump_up_dollars: ceilingKeepaBumpUpDollars,
    ceiling_keepa_bump_up_percentage: ceilingKeepaBumpUpPercentage,
    is_mm: isMMEnabled,
    mm_val: mmMultiplier,
  };
  triggerJson[slotKey] = slotValue;

  return triggerJson;
};

/**
 * Category 3: Constant variables.
 */
export const booksCategoryList = [
  {
    id: 0,
    name: 'No Category',
  },
  {
    id: 12290,
    name: 'Christian Books & Bibles',
  },
  {
    id: 9,
    name: 'History',
  },
  {
    id: 21,
    name: 'Reference',
  },
  {
    id: 10,
    name: 'Health, Fitness & Dieting',
  },
  {
    id: 86,
    name: 'Humor & Entertainment',
  },
  {
    id: 5267710011,
    name: 'Test Preparation',
  },
  {
    id: 18,
    name: 'Mystery, Thriller & Suspense',
  },
  {
    id: 3248857011,
    name: 'Calendars',
  },
  {
    id: 3,
    name: 'Business & Money',
  },
  {
    id: 6,
    name: 'Cookbooks, Food & Wine',
  },
  {
    id: 2,
    name: 'Biographies & Memoirs',
  },
  {
    id: 27,
    name: 'Travel',
  },
  {
    id: 20,
    name: 'Parenting & Relationships',
  },
  {
    id: 8975347011,
    name: 'Education & Teaching',
  },
  {
    id: 23,
    name: 'Romance',
  },
  {
    id: 3377866011,
    name: 'Politics & Social Sciences',
  },
  {
    id: 26,
    name: 'Sports & Outdoors',
  },
  {
    id: 75,
    name: 'Science & Math',
  },
  {
    id: 301889,
    name: 'Gay & Lesbian',
  },
  {
    id: 173507,
    name: 'Engineering & Transportation',
  },
  {
    id: 4,
    name: "Children's Books",
  },
  {
    id: 4366,
    name: 'Comics & Graphic Novels',
  },
  {
    id: 22,
    name: 'Religion & Spirituality',
  },
  {
    id: 17,
    name: 'Literature & Fiction',
  },
  {
    id: 28,
    name: 'Teen & Young Adult',
  },
  {
    id: 48,
    name: 'Crafts, Hobbies & Home',
  },
  {
    id: 25,
    name: 'Science Fiction & Fantasy',
  },
  {
    id: 5,
    name: 'Computers & Technology',
  },
  {
    id: 4736,
    name: 'Self-Help',
  },
  {
    id: 1,
    name: 'Arts & Photography',
  },
  {
    id: 10777,
    name: 'Law',
  },
  {
    id: 173514,
    name: 'Medical Books',
  },
];
