import React, { Component } from 'react';
import { Row, Col, Form, Button, Modal, Table, Spinner, ProgressBar } from 'react-bootstrap';

class ModalNew extends Component {
  state = {};

  handleOpen() {}

  render() {
    return (
      <React.Fragment>
        <Modal
          onEntered={this.handleOpen}
          show={this.props.showMPNewModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Form onSubmit={this.props.onFormSubmitAddMPBatch}>
            <Modal.Header closeButton>
              <div style={{ flex: 1, flexDirection: 'column' }}>
                <div>
                  <Form.Control
                    required
                    type="text"
                    name="mpBatchName"
                    className="input-text-trigger-name"
                    placeholder="Enter MP Batch Name here"
                  />
                </div>
                <div>
                  <Form.Control
                    type="text"
                    step="0.01"
                    name="mpBatchNotes"
                    max={100}
                    style={{
                      width: '500px',
                      borderRadius: '0',
                      border: 'none',
                      borderBottom: '1px solid #ccc',
                    }}
                    placeholder="Enter notes here"
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      <Form.Control
                        required
                        type="number"
                        step="0.01"
                        name="palletValue"
                        style={{
                          width: '250px',
                          borderRadius: '0',
                          border: 'none',
                          borderBottom: '1px solid #ccc',
                        }}
                        placeholder="Enter pallet Value here"
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <Form.Control as="select" name="teamId">
                        {this.props.teams.map((teamGroup) => {
                          return <option value={teamGroup.id}>{teamGroup.team_name}</option>;
                        })}
                      </Form.Control>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Header>

            <Modal.Body>
              <div style={{ display: 'flex', fontSize: '13px' }}>
                <div style={{ flex: 1 }}>
                  <Form.Check type="switch" name="isCompleted" id="is-completed" label="Completed?" />
                </div>

                <div style={{ flex: 1 }}>
                  <Form.Check type="switch" name="isShipped" id="is-shipped" label="Shipped?" />
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Check type="switch" name="isPaid" id="is-paid" label="Paid?" />
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Check type="switch" name="isRequiresLiftGate" id="requires-life-gate" label="Lift Gate?" />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  fontSize: '13px',
                  alignItems: 'center',
                  marginTop: '8px',
                  paddingTop: '10px',
                  borderTop: '1px solid #ddd',
                }}
              >
                <div style={{ flex: 1, paddingRight: '15px' }}>
                  <Form.Check type="switch" name="isFreightPaid" id="is-freight-paid" label="Freight Paid?" />
                </div>

                <div style={{ flex: 2, paddingRight: '15px' }}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>Freight Cost</Form.Label>
                    <Form.Control type="text" name="freightCost" />
                  </Form.Group>
                </div>

                <div style={{ flex: 2 }}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>Freight Charge</Form.Label>
                    <Form.Control type="text" name="freightCharge" />
                  </Form.Group>
                </div>
              </div>

              <div
                style={{
                  fontSize: '13px',
                  marginTop: '8px',
                  paddingTop: '10px',
                  borderTop: '1px solid #ddd',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formBuyerName">
                      <Form.Label>Buyer's Name</Form.Label>
                      <Form.Control type="text" name="buyerName" />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="buyerEmail">
                      <Form.Label>Buyer's Email</Form.Label>
                      <Form.Control type="text" name="buyerEmail" />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Form.Group controlId="buyerPhone">
                      <Form.Label>Buyer's Phone</Form.Label>
                      <Form.Control type="text" name="buyerPhone" />
                    </Form.Group>
                  </div>
                </div>

                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formBuyerName">
                      <Form.Label>Buyer's Address</Form.Label>
                      <Form.Control name="buyerAddress" as="textarea" rows={5} />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="buyerCity">
                      <Form.Label>Buyer's City</Form.Label>
                      <Form.Control type="text" name="buyerCity" />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="buyerState">
                      <Form.Label>Buyer's State</Form.Label>
                      <Form.Control type="text" name="buyerState" />
                    </Form.Group>
                  </div>

                  <div style={{ flex: 1 }}>
                    <Form.Group controlId="buyerZIP">
                      <Form.Label>Buyer's ZIP</Form.Label>
                      <Form.Control type="text" name="buyerZIP" />
                    </Form.Group>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    fontSize: '13px',
                    alignItems: 'center',
                    marginTop: '8px',
                    paddingTop: '10px',
                    borderTop: '1px solid #ddd',
                  }}
                >
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Buyer Commission</Form.Label>
                      <Form.Control type="text" name="buyerCommision" />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Seller Commission</Form.Label>
                      <Form.Control type="text" name="sellerCommision" />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Total Profit</Form.Label>
                      <Form.Control type="text" name="totalProfit" />
                    </Form.Group>
                  </div>
                  <div style={{ flex: 1, paddingRight: '15px' }}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>Per Pound</Form.Label>
                      <Form.Control type="text" name="perPound" />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              {this.props.isAddingBatch === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <Button variant="primary" type="submit">
                  Add
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNew;
