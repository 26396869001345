import React, { Component } from 'react';
import { Row, Col, Form, Modal, Button, InputGroup } from 'react-bootstrap';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { siteColors } from '../../../../includes/AppConfig';

class ModalAddTrigger extends Component {
  state = {
    selectedCategories: [],
    backupCats: [],
    currentColor: '',
  };

  constructor() {
    super();
    this.onFormSubmitAddTrigger = this.onFormSubmitAddTrigger.bind(this);
  }

  componentDidMount() {
    this.setState({ selectedCategories: [] });
  }

  onChange(e) {
    let selectedCategoriesClone = this.state.selectedCategories;
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      const indexInCategories = this.props.categoryList.findIndex((i) => {
        return i.id === +e.target.value;
      });
      const catName = this.props.categoryList[indexInCategories].name;
      selectedCategoriesClone.push({ id: +e.target.value, name: catName });
    } else {
      // or remove the value from the unchecked checkbox from the array
      const index = selectedCategoriesClone.indexOf(+e.target.value);
      selectedCategoriesClone.splice(index, 1);
    }
    // update the state with the new array of options
    this.setState({ selectedCategories: selectedCategoriesClone });
    console.log(selectedCategoriesClone);
  }

  onFormSubmitAddTrigger(event) {
    event.preventDefault();

    // Gather required fields
    const triggerName = event.target.elements.triggerName.value;
    const triggerJson = {
      categories: this.state.selectedCategories,
      min_rank: event.target.elements.minRank.value,
      max_rank: event.target.elements.maxRank.value,
      min_escore: event.target.elements.minEScore.value,
      max_escore: event.target.elements.maxEScore.value,
      price_comparison: event.target.elements.priceComparison.value,
      price_val: event.target.elements.priceVal.value,
      custom_color: this.state.currentColor,
    };

    this.props.onFormSubmitAddTrigger(triggerName, triggerJson);
  }

  render() {
    const ddOptions = siteColors.map((sc) => {
      return { value: sc, label: <div style={{ width: '20px', height: '20px', background: `${sc}` }}></div> };
    });

    return (
      <Modal
        onEntered={this.initVals}
        show={this.props.showAddModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitAddTrigger}>
          <Modal.Header closeButton>
            {this.props.premiumLevel === 1 ? (
              <InputGroup id="ig_triname">
                <InputGroup.Prepend style={{ margin: '7px' }}>
                  <Dropdown
                    options={ddOptions}
                    onChange={(col) => {
                      this.setState({ currentColor: col.value });
                    }}
                    value={this.state.currentColor !== '' ? this.state.currentColor : '#FFFFFF'}
                  />
                </InputGroup.Prepend>
                <Form.Control
                  required
                  type="text"
                  name="triggerName"
                  className="input-text-trigger-name"
                  placeholder="Enter Trigger Name Here"
                />
              </InputGroup>
            ) : (
              <Form.Control
                required
                type="text"
                name="triggerName"
                className="input-text-trigger-name"
                placeholder="Enter Trigger Name Here"
              />
            )}
            {/* <InputGroup id="ig_triname">
              <InputGroup.Prepend style={{ margin: "7px" }}>
                <Dropdown
                  options={ddOptions}
                  onChange={(col) => {
                    this.setState({ currentColor: col.value });
                  }}
                  value={this.state.currentColor !== "" ? this.state.currentColor : "#00743F"}
                />
              </InputGroup.Prepend>
              <Form.Control
                required
                type="text"
                name="triggerName"
                className="input-text-trigger-name"
                placeholder="Enter Trigger Name Here"
              />
            </InputGroup> */}
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: '13px', fontWeight: '500' }}>
              <Row>
                <Col md={4}>
                  {this.props.categoryList !== undefined &&
                    this.props.categoryList.length >= 1 &&
                    this.props.categoryList.slice(0, 11).map((category, _index) => {
                      return (
                        <Form.Group key={'cat' + category.id} controlId="formCategories">
                          <Form.Check
                            type="switch"
                            value={category.id}
                            style={{ color: category.id === 0 ? 'red' : 'black' }}
                            onChange={this.onChange.bind(this)}
                            id={'cat' + category.id}
                            label={category.name}
                          />
                        </Form.Group>
                      );
                    })}
                </Col>
                <Col md={4}>
                  {this.props.categoryList !== undefined &&
                    this.props.categoryList.length >= 1 &&
                    this.props.categoryList.slice(11, 22).map((category, _index) => {
                      return (
                        <Form.Group key={'cat' + category.id} controlId="formCategories">
                          <Form.Check
                            type="switch"
                            value={category.id}
                            onChange={this.onChange.bind(this)}
                            name={category.id}
                            id={'cat' + category.id}
                            label={category.name}
                          />
                        </Form.Group>
                      );
                    })}
                </Col>
                <Col md={4}>
                  {this.props.categoryList !== undefined &&
                    this.props.categoryList.length >= 1 &&
                    this.props.categoryList.slice(22, 50).map((category, _index) => {
                      return (
                        <Form.Group key={'cat' + category.id} controlId="formCategories">
                          <Form.Check
                            type="switch"
                            value={category.id}
                            onChange={this.onChange.bind(this)}
                            name={category.id}
                            id={'cat' + category.id}
                            label={category.name}
                          />
                        </Form.Group>
                      );
                    })}
                </Col>
              </Row>
              <Row style={{ marginTop: '10px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
                <Col md={3}>
                  <Form.Group controlId="formMinEScore">
                    <Form.Label>Min eScore</Form.Label>
                    <Form.Control required type="number" step="0.01" name="minEScore" placeholder="Min eScore" />
                  </Form.Group>
                </Col>
                <Col md={3} style={{ borderRight: '1px solid #ccc' }}>
                  <Form.Group controlId="formMaxEScore">
                    <Form.Label>Max eScore</Form.Label>
                    <Form.Control required type="number" step="0.01" name="maxEScore" placeholder="Max eScore" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formMinRank">
                    <Form.Label>Min SalesRank</Form.Label>
                    <Form.Control required type="number" step="0.01" name="minRank" placeholder="Min SalesRank" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formMaxRank">
                    <Form.Label>Max SalesRank</Form.Label>
                    <Form.Control required type="number" step="0.01" name="maxRank" placeholder="Max SalesRank" />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="formCeiling1Options">
                    <Form.Label>Comparison Type</Form.Label>
                    <Form.Control as="select" name="priceComparison">
                      <option value="Fixed Price">Fixed Price</option>
                      <option value="Price Per Pound">Price Per Pound</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formMaxRank">
                    <Form.Label>Price</Form.Label>
                    <Form.Control required type="number" step="0.01" name="priceVal" placeholder="" />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAddTrigger;
