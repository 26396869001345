import React, { Component } from 'react';
import TopMenu from '../components/TopMenu';
import Cookies from 'universal-cookie';
import {
  getMPSources,
  updateCompleteMPSource,
  addCompleteMPSource,
  deleteMPSource,
  downloadManifest,
} from '../includes/HelperMpSources';
import { loadTeams } from '../includes/HelperUsers';
import { Container, Row, Col, Button, ProgressBar, Spinner, Form } from 'react-bootstrap';
import ModalDetails from '../components/MPBatch/ModalDetails';
import ModalNew from '../components/MPBatch/ModalNew';
import Lottie from 'react-lottie';
import animationData from '../assets/animations/73134-click-and-graph.json';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const cookies = new Cookies();

class MpBatches extends Component {
  state = {
    admin_data: cookies.get('admin_data'),
    showNewModal: false,
    isLoadingMPBatches: false,
    teams: [],
    filtered: [],
    mp_sources: [],
    mp_sources_original: [],
    selectedBatch: {},
    showMPModal: false,
    showMPNewModal: false,
    isUpdatingBatch: false,
    isAddingBatch: false,
    downloadingManifest: false,
    downloadingManifestID: 0,
    globalSearchText: '',
  };
  constructor() {
    super();
    this.onFilteredChangeCustom = this.onFilteredChangeCustom.bind(this);
    this.onFormSubmitUpdateMPBatch = this.onFormSubmitUpdateMPBatch.bind(this);
    this.onFormSubmitAddMPBatch = this.onFormSubmitAddMPBatch.bind(this);
    this.handleDeleteMPSource = this.handleDeleteMPSource.bind(this);
  }

  async initiate() {
    this.setState({ isLoadingMPBatches: true });
    const teamsData = await loadTeams(this.state.admin_data);
    if ('status' in teamsData && teamsData.status === 'success')
      this.setState({ teams: teamsData.data }, async () => {
        const mpSources = await getMPSources(this.state.admin_data);
        if ('status' in mpSources && mpSources.status) {
          this.setState({ mp_sources: mpSources.data, mp_sources_original: mpSources.data }, () => {
            this.setState({ isLoadingMPBatches: false });
          });
        }
      });
  }

  async onFormSubmitUpdateMPBatch(e, dates) {
    this.setState({ isUpdatingBatch: true });

    const jsonData = {
      pallet_notes: e.target.elements.mpBatchNotes.value,
      completed: e.target.elements.isCompleted.checked,
      shipped: e.target.elements.isShipped.checked,
      paid: e.target.elements.isPaid.checked,
      is_case_closed: e.target.elements.isCaseClosed.checked,
      team_id: e.target.elements.teamId.value,
      requires_lift_gate: e.target.elements.isRequiresLiftGate.checked,
      freight_paid: 0,
      freight_cost:
        isNaN(e.target.elements.freightCost.value) || e.target.elements.freightCost.value === ''
          ? 0
          : e.target.elements.freightCost.value,
      freight_charge:
        isNaN(e.target.elements.freightCharge.value) || e.target.elements.freightCharge.value === ''
          ? 0
          : e.target.elements.freightCharge.value,
      buyer_name: e.target.elements.buyerName.value,
      buyer_email: e.target.elements.buyerEmail.value,
      buyer_contact_number: e.target.elements.buyerPhone.value,
      buyer_address: e.target.elements.buyerAddress.value,
      buyer_city: e.target.elements.buyerCity.value,
      buyer_state: e.target.elements.buyerState.value,
      buyer_zip: e.target.elements.buyerZIP.value === '' ? 0 : e.target.elements.buyerZIP.value,
      buyer_commission:
        isNaN(e.target.elements.buyerCommission.value) || e.target.elements.buyerCommission.value === ''
          ? 0
          : e.target.elements.buyerCommission.value,
      seller_commission:
        isNaN(e.target.elements.sellerCommission.value) || e.target.elements.sellerCommission.value === ''
          ? 0
          : e.target.elements.sellerCommission.value,
      total_profit:
        isNaN(e.target.elements.totalProfit.value) || e.target.elements.totalProfit.value === ''
          ? 0
          : e.target.elements.totalProfit.value,
      // per_pound:
      //   isNaN(e.target.elements.perPound.value) || e.target.elements.perPound.value === ''
      //     ? 0
      //     : e.target.elements.perPound.value,

      // Acounting fields
      freight_booked_date: dates.freightBookedOn || null,
      freight_paid_date: dates.freightPaidOn || null,
      freight_profit: e.target.elements.freightProfit.value || null,
      verified_freight: e.target.elements.isBKFreightPaid.checked ? 1 : 0,

      payment_sent_date: dates.paymentSentOn || null,
      payment_sent: e.target.elements.paymentSent.value || null,
      verified_payment_sent: e.target.elements.isBKPaymentSent.checked ? 1 : 0,

      payment_received_date: dates.paymentReceivedOn || null,
      payment_received_amt: e.target.elements.paymentReceived.value || null,
      payment_received_id: e.target.elements.paymentReceivedID.value || null,
      verified_payment_received: e.target.elements.isBKPaymentReceived.checked ? 1 : 0,

      verified_commissions: e.target.elements.isCommisions.checked ? 1 : 0,

      freight_adjustment_amt: e.target.elements.freightAdjustmentAmount.value || null,
      freight_adjustment_date: dates.freightAdjustmentOn || null,
      reimbursed_amt: e.target.elements.reimbursedAmount.value || null,
      reimbursed_date: dates.reimbursedOn || null,
      reimbursed_id: e.target.elements.reimbursedAmountPaymentID.value || null,
      verified_adjustments: e.target.elements.isBKFreightAdjustments.checked ? 1 : 0,

      refund_amt: e.target.elements.refundAmount.value || null,
      refund_on: dates.refundFreightOn || null,
      refund_freight_amt: e.target.elements.refundFreightAmount.value || null,
      refund_freight_on: dates.refundFreightOn || null,
      verified_refunds: e.target.elements.isBKRefunds.checked ? 1 : 0,

      profit_sent_to_siq_on: dates.profitSentToScoutIQOn || null,
      verified_profits: e.target.elements.isBKProfitProcessed.checked ? 1 : 0,
    };

    const response = await updateCompleteMPSource(this.state.admin_data, this.state.selectedBatch.id, jsonData);

    if ('status' in response && response.status === true) {
      this.setState({ mp_sources: response.data, mp_sources_original: response.data });

      // console.log(['>', '<'].indexOf(e.target.value.toString()));
      const searchString = !isNaN(this.state.globalSearchText)
        ? Number(this.state.globalSearchText)
        : this.state.globalSearchText.includes('>')
        ? Number(this.state.globalSearchText.replace(/\D/g, ''))
        : this.state.globalSearchText;

      if (searchString) {
        const filtered = this.state.mp_sources_original.filter((mp_source) => {
          // console.log(keys);

          if (
            this.state.globalSearchText.includes('>') &&
            !isNaN(searchString) &&
            (Number(mp_source['buyer_commission']) >= searchString ||
              Number(mp_source['freight_adjustment_amt']) >= searchString ||
              Number(mp_source['freight_charge']) >= searchString ||
              Number(mp_source['freight_cost']) >= searchString ||
              Number(mp_source['freight_paid']) >= searchString ||
              Number(mp_source['freight_profit']) >= searchString ||
              Number(mp_source['pallet_value']) >= searchString ||
              Number(mp_source['payment_received_amt']) >= searchString ||
              Number(mp_source['payment_sent']) >= searchString ||
              Number(mp_source['reimbursed_amt']) >= searchString ||
              Number(mp_source['agg']) >= searchString ||
              Number(mp_source['total_profit']) >= searchString)
          ) {
            return true;
          } else {
            let isFound = false;
            const keys = Object.keys(mp_source);
            for (let j = 0; j < keys.length; j++) {
              if (keys[j] === 'id') continue;
              if (mp_source[keys[j]]) {
                if (mp_source[keys[j]].toString().toLowerCase().includes(searchString)) {
                  isFound = true;
                  break;
                }
              }
            }
            return isFound;
          }
        });

        this.setState({ mp_sources: filtered });
      } else this.setState({ mp_sources: this.state.mp_sources_original });
    }

    this.setState({ isUpdatingBatch: false, showMPModal: false });
  }

  async onFormSubmitAddMPBatch(e) {
    this.setState({ isAddingBatch: true });
    e.preventDefault();

    const jsonData = {
      name: e.target.elements.mpBatchName.value,
      pallet_notes: e.target.elements.mpBatchNotes.value,
      pallet_value: e.target.elements.palletValue.value,
      date_ordered: new Date().toISOString().substring(0, 10),
      team_id: e.target.elements.teamId.value,
      completed: e.target.elements.isCompleted.checked,
      shipped: e.target.elements.isShipped.checked,
      paid: e.target.elements.isPaid.checked,
      requires_lift_gate: e.target.elements.isRequiresLiftGate.checked,
      freight_paid: e.target.elements.isFreightPaid.checked,
      freight_cost:
        isNaN(e.target.elements.freightCost.value) || e.target.elements.freightCost.value === ''
          ? 0
          : e.target.elements.freightCost.value,
      freight_charge:
        isNaN(e.target.elements.freightCharge.value) || e.target.elements.freightCharge.value === ''
          ? 0
          : e.target.elements.freightCharge.value,
      buyer_name: e.target.elements.buyerName.value,
      buyer_email: e.target.elements.buyerEmail.value,
      buyer_contact_number: e.target.elements.buyerPhone.value,
      buyer_address: e.target.elements.buyerAddress.value,
      buyer_city: e.target.elements.buyerCity.value,
      buyer_state: e.target.elements.buyerState.value,
      buyer_zip: e.target.elements.buyerZIP.value === '' ? 0 : e.target.elements.buyerZIP.value,
      buyer_commission:
        isNaN(e.target.elements.buyerCommision.value) || e.target.elements.buyerCommision.value === ''
          ? 0
          : e.target.elements.buyerCommision.value,
      seller_commission:
        isNaN(e.target.elements.sellerCommision.value) || e.target.elements.sellerCommision.value === ''
          ? 0
          : e.target.elements.sellerCommision.value,
      total_profit:
        isNaN(e.target.elements.totalProfit.value) || e.target.elements.totalProfit.value === ''
          ? 0
          : e.target.elements.totalProfit.value,
      // per_pound:
      //   isNaN(e.target.elements.perPound.value) || e.target.elements.perPound.value === ''
      //     ? 0
      //     : e.target.elements.perPound.value,
    };

    const response = await addCompleteMPSource(this.state.admin_data, this.state.selectedBatch.id, jsonData);

    if ('status' in response && response.status === true) {
      this.setState({ mp_sources: response.data });
    }

    this.setState({ isAddingBatch: false, showMPNewModal: false });
  }

  handleDeleteMPSource(mpSourceId) {
    console.log(`MP SOURCE ID: ${mpSourceId}`);

    confirmAlert({
      title: 'Delete Shipment?',
      message:
        'This will permanently unmap items associated with this Shipment ID' +
        mpSourceId +
        '. Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const result = await deleteMPSource(this.state.admin_data, mpSourceId);
            if ('status' in result && result.status) this.setState({ mp_sources: result.data });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }

  async handleDownloadManifest(rowData) {
    this.setState({ downloadingManifest: true, downloadingManifestID: rowData.id });
    const result = await downloadManifest(this.state.admin_data, rowData.name);
    if ('status' in result && result.status) {
      let csvContent = 'data:text/csv;charset=utf-8,';

      csvContent +=
        'ASIN,TITLE,ESCORE,CURRENT TARGET PRICE,CURRENT TARGET PROFIT,CONSERVATIVE TARGET PRICE,CONSERVATIVE TARGET PROFIT,MARKETPLACE PRICE,SALESRANK,SCANNED_ON\r\n';

      result.data.forEach((row) => {
        const scannedDate = new Date(row.scanned_on);
        const yearScanned = scannedDate.getFullYear();
        let monthScanned = (1 + scannedDate.getMonth()).toString();
        monthScanned = monthScanned.length > 1 ? monthScanned : '0' + monthScanned;

        let dayScanned = scannedDate.getDate().toString();
        dayScanned = dayScanned.length > 1 ? dayScanned : '0' + dayScanned;

        csvContent +=
          row.asin +
          ',' +
          row.title.replace(/[^\w\s]/gi, '') +
          ',' +
          row.escore +
          ',' +
          row.target_list_price +
          ',' +
          row.estimated_profit +
          ',' +
          row.target_list_price_conservative +
          ',' +
          row.estimated_profit_conservative +
          ',' +
          row.marketplace_price +
          ',' +
          row.sales_rank +
          ',' +
          (row.scanned_on === '' ? '' : monthScanned + '-' + dayScanned + '-' + yearScanned) +
          '\r\n';
      });

      var encodedUri = encodeURI(csvContent);
      const fixedEncodedURI = encodedUri.replaceAll('#', '%23');
      var link = document.createElement('a');
      link.setAttribute('href', fixedEncodedURI);
      link.setAttribute('download', `${rowData.name}.csv`);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
    }
    this.setState({ downloadingManifest: false });
  }

  componentDidMount() {
    this.initiate();
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      //console.log(rowInfo);
      return {
        style: {
          background: rowInfo.original.is_case_closed === 1 ? '#cfffd1' : 'white',
        },
      };
    }
    return {};
  };

  render() {
    const defaultLottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <React.Fragment>
        <TopMenu
          isReevaluate={
            'is_re_evaluate' in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          handlenavigationroutes={(routeName) => {
            this.props.history.push(routeName);
          }}
          adminData={this.state.admin_data}
          history={this.props.history}
          activeKey="/mp-inbound"
        />

        <ModalDetails
          teams={this.state.teams}
          isUpdatingBatch={this.state.isUpdatingBatch}
          onFormSubmitUpdateMPBatch={this.onFormSubmitUpdateMPBatch}
          showMPModal={this.state.showMPModal}
          handleClose={() => this.setState({ showMPModal: false })}
          selectedBatch={this.state.selectedBatch}
        />

        <ModalNew
          teams={this.state.teams}
          isAddingBatch={this.state.isAddingBatch}
          onFormSubmitAddMPBatch={this.onFormSubmitAddMPBatch}
          showMPNewModal={this.state.showMPNewModal}
          handleClose={() => this.setState({ showMPNewModal: false })}
        />

        <Container fluid={true} style={{ marginTop: '15px' }}>
          <Row style={{ marginTop: '20px' }}>
            <Col md={8}>
              <div>
                <h3>Marketplace Batches</h3>
              </div>
            </Col>
            <Col md={4}>
              <div style={{ display: 'flex', textAlign: 'right', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div>
                  <Form.Control
                    placeholder="Global Search"
                    type="text"
                    value={this.state.globalSearchText}
                    onChange={(e) => {
                      this.setState({ globalSearchText: e.target.value });
                      if (e.target.value) {
                        // console.log(['>', '<'].indexOf(e.target.value.toString()));
                        const searchString = !isNaN(e.target.value)
                          ? Number(e.target.value)
                          : e.target.value.includes('>')
                          ? Number(e.target.value.replace(/\D/g, ''))
                          : e.target.value;

                        if (searchString) {
                          const filtered = this.state.mp_sources_original.filter((mp_source) => {
                            // console.log(keys);

                            if (
                              e.target.value.includes('>') &&
                              !isNaN(searchString) &&
                              (Number(mp_source['buyer_commission']) >= searchString ||
                                Number(mp_source['freight_adjustment_amt']) >= searchString ||
                                Number(mp_source['freight_charge']) >= searchString ||
                                Number(mp_source['freight_cost']) >= searchString ||
                                Number(mp_source['freight_paid']) >= searchString ||
                                Number(mp_source['freight_profit']) >= searchString ||
                                Number(mp_source['pallet_value']) >= searchString ||
                                Number(mp_source['payment_received_amt']) >= searchString ||
                                Number(mp_source['payment_sent']) >= searchString ||
                                Number(mp_source['reimbursed_amt']) >= searchString ||
                                Number(mp_source['agg']) >= searchString ||
                                Number(mp_source['total_profit']) >= searchString)
                            ) {
                              return true;
                            } else {
                              let isFound = false;
                              const keys = Object.keys(mp_source);
                              for (let j = 0; j < keys.length; j++) {
                                if (keys[j] === 'id') continue;
                                if (mp_source[keys[j]]) {
                                  if (mp_source[keys[j]].toString().toLowerCase().includes(searchString)) {
                                    isFound = true;
                                    break;
                                  }
                                }
                              }
                              return isFound;
                            }
                          });

                          this.setState({ mp_sources: filtered });
                        } else this.setState({ mp_sources: this.state.mp_sources_original });
                      } else this.setState({ mp_sources: this.state.mp_sources_original });
                    }}
                  />
                </div>
                <div>
                  {(this.state.admin_data.access_level === 0 || this.state.admin_data.access_level === 1) && (
                    <Button
                      className="actionButtonLessPadding"
                      style={{ marginLeft: '8px' }}
                      variant="success"
                      onClick={() => this.setState({ showMPNewModal: true })}
                    >
                      Add New
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
              {this.state.isLoadingMPBatches ? (
                <div>
                  <Lottie options={defaultLottieOptions} height={400} width={400} />
                </div>
              ) : (
                <div style={{ paddingBottom: '100px' }}>
                  <ReactTable
                    showPagination={true}
                    // defaultPageSize={10}
                    pageSize={100}
                    getTrProps={this.getTrProps}
                    processing={true}
                    data={this.state.mp_sources}
                    filterable
                    filtered={this.state.filtered}
                    onFilteredChange={(filtered, column, value) => {
                      this.onFilteredChangeCustom(value, column.id || column.accessor);
                    }}
                    defaultFilterMethod={(filter, row, column) => {
                      const id = filter.pivotId || filter.id;
                      if (typeof filter.value === 'object') {
                        return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                      } else {
                        return row[id] !== undefined
                          ? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                          : true;
                      }
                    }}
                    columns={[
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Batch Name
                          </div>
                        ),
                        width: 150,
                        id: 'name',
                        accessor: 'name',
                        Cell: ({ value }) => <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>,
                        style: { 'text-align': 'center', 'align-self': 'center' },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Date Ordered
                          </div>
                        ),
                        width: 150,
                        id: 'ordered_on',
                        accessor: 'ordered_on',
                        Cell: ({ value }) => <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>,
                        style: { 'text-align': 'center', 'align-self': 'center' },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Status
                          </div>
                        ),
                        id: 'status',
                        accessor: (d) => {
                          let status = '';
                          if (d.completed === 1) status += 'COMPLETED, ';
                          if (d.shipped === 1) status += 'SHIPPED, ';
                          if (d.paid === 1) status += 'PAID, ';
                          if (Number(d.total_profit) > 0) status += `PROFIT: $${d.total_profit}, `;

                          status = status.slice(0, -2);

                          if (status === '') status = '-';
                          return status;
                        },
                        Cell: ({ value }) => <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>,
                        style: { 'text-align': 'center', 'align-self': 'center' },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Notes
                          </div>
                        ),
                        id: 'pallet_notes',
                        accessor: 'pallet_notes',
                        Cell: ({ value }) => <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>,
                        style: { 'text-align': 'center', 'align-self': 'center', 'white-space': 'unset' },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Value
                          </div>
                        ),
                        id: 'pallet_value',
                        accessor: 'pallet_value',
                        filterable: true,
                        width: 120,
                        Cell: ({ value }) => <span style={{ whiteSpace: 'pre-wrap' }}>${value}</span>,
                        style: { 'text-align': 'center', 'align-self': 'center', 'white-space': 'unset' },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Filled
                          </div>
                        ),
                        id: 'agg',
                        accessor: 'agg',
                        filterable: true,
                        width: 100,
                        Cell: ({ value }) => (
                          <span style={{ whiteSpace: 'pre-wrap' }}>${parseFloat(value).toFixed(0)}</span>
                        ),
                        style: { 'text-align': 'center', 'align-self': 'center', 'white-space': 'unset' },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Profit
                          </div>
                        ),
                        id: 'total_profit',
                        accessor: 'total_profit',
                        filterable: true,
                        width: 100,
                        Cell: ({ value }) => (
                          <span style={{ whiteSpace: 'pre-wrap' }}>${parseFloat(value).toFixed(0)}</span>
                        ),
                        style: { 'text-align': 'center', 'align-self': 'center', 'white-space': 'unset' },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Buyer's Name
                          </div>
                        ),
                        id: 'buyer_name',
                        accessor: 'buyer_name',
                        Cell: ({ value }) => <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>,
                        style: { 'text-align': 'center', 'align-self': 'center', 'white-space': 'unset' },
                        filterable: true,
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Supplier's Name
                          </div>
                        ),
                        id: 'team_name',
                        accessor: 'team_name',
                        Cell: ({ value }) => <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>,
                        style: { 'text-align': 'center', 'align-self': 'center', 'white-space': 'unset' },
                        filterable: true,
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Progress
                          </div>
                        ),
                        id: 'progress_bar',
                        filterable: false,
                        accessor: (d) => {
                          return (
                            <div>
                              <ProgressBar
                                variant={
                                  d.per_completed < 1
                                    ? 'danger'
                                    : d.per_completed >= 1 && d.per_completed < 25
                                    ? 'warning'
                                    : d.per_completed >= 25 && d.per_completed < 99
                                    ? 'info'
                                    : 'success'
                                }
                                now={d.per_completed < 1 ? 10 : d.per_completed}
                                label={`${d.per_completed}%`}
                              />
                            </div>
                          );
                        },
                        Cell: ({ value }) => <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>,
                        style: { 'text-align': 'center', 'align-self': 'center', 'white-space': 'unset' },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                            }}
                          >
                            Actions
                          </div>
                        ),
                        id: 'actions',
                        style: { 'white-space': 'unset', 'align-self': 'center', 'text-align': 'center' },
                        filterable: false,
                        width: 165,
                        Cell: (cellInfo) => {
                          return (
                            <div>
                              <Button
                                title="Edit Item"
                                size="sm"
                                style={{ marginRight: '20px' }}
                                variant="dark"
                                onClick={() => {
                                  this.setState({ selectedBatch: cellInfo.original }, () => {
                                    this.setState({ showMPModal: true });
                                  });
                                  //this.handleEditItem(cellInfo.original);
                                }}
                              >
                                <i style={{ color: 'white' }} className="fa fa-list" />
                              </Button>

                              <Button
                                title="Download Manifest"
                                size="sm"
                                style={{ marginRight: '20px' }}
                                variant="dark"
                                onClick={() => {
                                  this.handleDownloadManifest(cellInfo.original);
                                  //this.handleEditItem(cellInfo.original);
                                }}
                              >
                                {this.state.downloadingManifest === true &&
                                this.state.downloadingManifestID === cellInfo.original.id ? (
                                  <Spinner
                                    style={{ width: '15px', height: '15px' }}
                                    animation="border"
                                    role="status"
                                  ></Spinner>
                                ) : (
                                  <i style={{ color: 'white' }} className="fa fa-cloud-download" />
                                )}
                              </Button>

                              <Button
                                title="Edit Item"
                                size="sm"
                                style={{ marginRight: '20px' }}
                                variant="dark"
                                onClick={() => this.handleDeleteMPSource(cellInfo.original.id)}
                              >
                                <i style={{ color: 'white' }} className="fa fa-close" />
                              </Button>
                            </div>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default MpBatches;
