import React, { Component } from 'react';
import TopMenu from '../components/TopMenu';
import { Container, Row, Col, Spinner, FormControl, Form, InputGroup, Button } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { getTBConfiguration, reconfigTB, updateTBNameHelper } from '../includes/HelperTriggerBatches';
import { Tabs, DragTabList, DragTab, PanelList, Panel } from 'react-tabtab';
import { simpleSwitch } from 'react-tabtab/lib/helpers/move';
import * as customStyle from 'react-tabtab/lib/themes/bootstrap';
// Import Level 1 Trigger Module Components
import AmazonTriggers from '../components/TriggerBatch/Level1/AmazonTriggers';
import AmazonCATriggers from '../components/TriggerBatch/Level1/AmazonCATriggers';
import AmazonNewTriggers from '../components/TriggerBatch/Level1/AmazonNewTriggers';
import WhaleSaleTrigger from '../components/TriggerBatch/Level1/WhaleSaleTrigger';
import HitListTriggers from '../components/TriggerBatch/Level1/HitListTriggers';
import WholeSaleTriggers from '../components/TriggerBatch/Level1/WholeSaleTriggers';
import BookScouterTrigger from '../components/TriggerBatch/Level1/BookScouterTrigger';
import TextBookTriggers from '../components/TriggerBatch/Level1/TextBookTriggers';

const cookies = new Cookies();

class TriggerBatch extends Component {
  state = {
    triggerBatchId: this.props.match.params.id,
    triggerBatchName: this.props.history.location.state?.triggerBatchName,
    admin_data: cookies.get('admin_data'),
    activeIndex: 0,
    isEditTBName: false,
    batchConfiguration: [],
    isLoadingConfiguration: false,
    updatingTBName: false,
  };

  constructor(props) {
    super(props);
    this.handleTabSequenceChange = this.handleTabSequenceChange.bind(this);
    this.loadTriggerBatchConfiguration = this.loadTriggerBatchConfiguration.bind(this);
    this.updateTBConfig = this.updateTBConfig.bind(this);
    this.updateBSVendors = this.updateBSVendors.bind(this);
    this.handleReConfig = this.handleReConfig.bind(this);
    this.handleTargetChangeKeyPress = this.handleTargetChangeKeyPress.bind(this);
    this.updateTBName = this.updateTBName.bind(this);
    this.updateCAD2USD = this.updateCAD2USD.bind(this);
    this.updateInShipRate = this.updateInShipRate.bind(this);
  }

  componentDidMount() {
    this.loadTriggerBatchConfiguration();
  }

  updateTBConfig(level, new_amz_config, callback) {
    let batchConfiguration = { ...this.state.batchConfiguration };
    batchConfiguration[level] = JSON.stringify(new_amz_config);

    this.setState({ batchConfiguration }, () => {
      if (undefined !== callback) callback();
    });
  }

  updateBSVendors(vendorsList) {
    let batchConfiguration = { ...this.state.batchConfiguration };
    let batchConfigurationBS = JSON.parse(batchConfiguration.conf_bookscouter);
    batchConfigurationBS.vendors = JSON.stringify(vendorsList);
    batchConfiguration.conf_bookscouter = JSON.stringify(batchConfigurationBS);
    this.setState({ batchConfiguration });
  }

  async handleTabSequenceChange({ oldIndex, newIndex }) {
    const updateTabs = simpleSwitch(this.getTabs(), oldIndex, newIndex);

    if ('high_level' in this.state.batchConfiguration && this.state.batchConfiguration.high_level !== null) {
      const config_high_level = JSON.parse(this.state.batchConfiguration.high_level);
      let new_hl_config = [];

      updateTabs.forEach((tabInfo) => {
        const index = config_high_level.findIndex(function (module) {
          return tabInfo.title === module.module_name;
        });
        if (index >= 0) new_hl_config.push(config_high_level[index]);
        else {
          if (tabInfo.title === 'Amazon New')
            new_hl_config.push({ module_name: 'Amazon New', target_diff: '0', enabled: false });
          else return;
        }
      });

      this.setState({ activeIndex: newIndex });
      this.updateTBConfig('high_level', new_hl_config);
      await reconfigTB(this.state.admin_data, this.state.triggerBatchId, 'high_level', JSON.stringify(new_hl_config));
    }
  }

  async loadTriggerBatchConfiguration() {
    this.setState({ isLoadingConfiguration: true });
    const response = await getTBConfiguration(this.state.admin_data, this.state.triggerBatchId);
    if (response.status === 'success') {
      let batchConfiguration = {};
      if (response.data !== '') {
        batchConfiguration = response.data;
        this.setState({ batchConfiguration: batchConfiguration }, () => {
          //this.arrangeTabs();
        });
      }
    }
    this.setState({ isLoadingConfiguration: false });
  }

  getTabs() {
    let tabs = [
      {
        title: 'HitList',
        targetDiff: 0,
        enabled: true,
        content: (
          <HitListTriggers
            batchConfiguration={this.state.batchConfiguration}
            admin_data={this.state.admin_data}
            triggerBatchId={this.props.match.params.id}
          />
        ),
      },
      {
        title: `Amazon`,
        targetDiff: 0,
        enabled: true,
        content: (
          <AmazonTriggers
            updateTBConfig={this.updateTBConfig}
            batchConfiguration={this.state.batchConfiguration}
            admin_data={this.state.admin_data}
            triggerBatchId={this.props.match.params.id}
          />
        ),
      },
    ];

    this.state.admin_data.is_ca === true &&
      tabs.push({
        title: `Amazon CA`,
        targetDiff: 0,
        enabled: true,
        content: (
          <AmazonCATriggers
            updateInShipRate={this.updateInShipRate}
            updateCAD2USD={this.updateCAD2USD}
            updateTBConfig={this.updateTBConfig}
            batchConfiguration={this.state.batchConfiguration}
            admin_data={this.state.admin_data}
            triggerBatchId={this.props.match.params.id}
            reconfigInProgress={this.state.reconfigInProgress}
          />
        ),
      });

    this.state.admin_data.is_amz_new === true &&
      tabs.push({
        title: `Amazon New`,
        targetDiff: 0,
        enabled: true,
        content: (
          <AmazonNewTriggers
            updateTBConfig={this.updateTBConfig}
            batchConfiguration={this.state.batchConfiguration}
            admin_data={this.state.admin_data}
            triggerBatchId={this.props.match.params.id}
          />
        ),
      });

    tabs.push({
      title: `WholeSale`,
      targetDiff: 0,
      enabled: true,
      content: (
        <WholeSaleTriggers
          updateTBConfig={this.updateTBConfig}
          batchConfiguration={this.state.batchConfiguration}
          admin_data={this.state.admin_data}
          triggerBatchId={this.props.match.params.id}
        />
      ),
    });

    tabs.push({
      title: `BookStore`,
      targetDiff: 0,
      enabled: true,
      content: (
        <WhaleSaleTrigger
          updateTBConfig={this.updateTBConfig}
          batchConfiguration={this.state.batchConfiguration}
          admin_data={this.state.admin_data}
          triggerBatchId={this.props.match.params.id}
        />
      ),
    });

    tabs.push({
      title: `Textbook`,
      targetDiff: 0,
      enabled: true,
      content: (
        <TextBookTriggers
          updateTBConfig={this.updateTBConfig}
          batchConfiguration={this.state.batchConfiguration}
          admin_data={this.state.admin_data}
          triggerBatchId={this.props.match.params.id}
        />
      ),
    });

    tabs.push({
      title: `BookScouter`,
      targetDiff: 0,
      enabled: true,
      content: (
        <BookScouterTrigger
          updateTBConfig={this.updateTBConfig}
          updateBSVendors={this.updateBSVendors}
          batchConfiguration={this.state.batchConfiguration}
          admin_data={this.state.admin_data}
          triggerBatchId={this.props.match.params.id}
        />
      ),
    });

    let tabsInOrder = [];

    const highLevelBatchConfiguration =
      'high_level' in this.state.batchConfiguration && this.state.batchConfiguration.high_level !== ''
        ? JSON.parse(this.state.batchConfiguration.high_level)
        : [];

    highLevelBatchConfiguration.forEach((tabConfig, _index) => {
      var index = tabs.findIndex(function (tab) {
        return tab.title === tabConfig.module_name;
      });

      if (index >= 0) {
        let thisTab = tabs.splice(index, 1)[0];
        thisTab.targetDiff = _index === 0 ? null : tabConfig.target_diff;
        thisTab.enabled = tabConfig.enabled;
        tabsInOrder.push(thisTab);
      }
    });

    tabsInOrder = tabsInOrder.concat(tabs);

    return tabsInOrder;
  }

  onChangeHighLevelTargetDiff(e, title) {
    const targetDiff = e.target.value;

    const highLevelBatchConfiguration =
      'high_level' in this.state.batchConfiguration && this.state.batchConfiguration.high_level !== ''
        ? JSON.parse(this.state.batchConfiguration.high_level)
        : [];

    const index = highLevelBatchConfiguration.findIndex(function (module) {
      return module.module_name === title;
    });
    if (index >= 0) {
      highLevelBatchConfiguration[index].target_diff = targetDiff;
      this.updateTBConfig('high_level', highLevelBatchConfiguration);
    }
  }

  onChangeHighLevelToggle(e, title) {
    const isChecked = e.target.checked;

    const highLevelBatchConfiguration =
      'high_level' in this.state.batchConfiguration && this.state.batchConfiguration.high_level !== ''
        ? JSON.parse(this.state.batchConfiguration.high_level)
        : [];

    const index = highLevelBatchConfiguration.findIndex(function (module) {
      return module.module_name === title;
    });
    if (index >= 0) {
      highLevelBatchConfiguration[index].enabled = isChecked;
      let batchConfiguration = { ...this.state.batchConfiguration };
      batchConfiguration.high_level = JSON.stringify(highLevelBatchConfiguration);
      this.setState({ batchConfiguration }, () => {
        this.handleReConfig();
      });
    } else {
      console.log('Not Found: ' + index);
      console.log(highLevelBatchConfiguration);
      console.log(highLevelBatchConfiguration);
    }
  }

  async handleReConfig() {
    this.setState({ reconfigInProgress: true });
    await reconfigTB(
      this.state.admin_data,
      this.state.triggerBatchId,
      'high_level',
      this.state.batchConfiguration.high_level
    );
    this.setState({ reconfigInProgress: false });
  }

  handleTargetChangeKeyPress(target) {
    if (target.charCode === 13) {
      this.handleReConfig();
    }
  }

  async updateCAD2USD(e) {
    e.preventDefault();
    var cad2usd = e.target.elements.cad2usd.value;

    const highLevelBatchConfiguration =
      'high_level' in this.state.batchConfiguration && this.state.batchConfiguration.high_level !== ''
        ? JSON.parse(this.state.batchConfiguration.high_level)
        : [];

    const index = highLevelBatchConfiguration.findIndex(function (module) {
      return module.module_name === 'Amazon CA';
    });
    if (index >= 0) {
      highLevelBatchConfiguration[index].cad2usd = cad2usd;
      this.updateTBConfig('high_level', highLevelBatchConfiguration, () => {
        this.handleReConfig();
      });
    }
  }

  async updateInShipRate(e) {
    e.preventDefault();
    var inShipRate = e.target.elements.inShipRate.value;

    const highLevelBatchConfiguration =
      'high_level' in this.state.batchConfiguration && this.state.batchConfiguration.high_level !== ''
        ? JSON.parse(this.state.batchConfiguration.high_level)
        : [];

    const index = highLevelBatchConfiguration.findIndex(function (module) {
      return module.module_name === 'Amazon CA';
    });
    if (index >= 0) {
      highLevelBatchConfiguration[index].inShipRate = inShipRate;
      this.updateTBConfig('high_level', highLevelBatchConfiguration, () => {
        this.handleReConfig();
      });
    }
  }

  async updateTBName(e) {
    e.preventDefault();
    var newBatchName = e.target.elements.triggerBatchName.value;
    this.setState({ updatingTBName: true });

    const response = await updateTBNameHelper(this.state.admin_data, this.state.triggerBatchId, newBatchName);

    if ('status' in response && response.status === true) {
      this.setState({ triggerBatchName: newBatchName, isEditTBName: false, updatingTBName: false });
      let currentURL = window.location.href;
      const finalPart = currentURL.split('/')[5];
      currentURL = currentURL.replace(finalPart, newBatchName);
      window.history.replaceState('', '', currentURL);
    }
  }

  render() {
    const { activeIndex } = this.state;
    const tabs = this.getTabs();

    //const { tabs, activeIndex } = this.state;
    const tabsTemplate = [];
    const panelTemplate = [];
    tabs.forEach((tab, index) => {
      tabsTemplate.push(
        <DragTab key={index}>
          <div className="tabTitle">
            <div>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  onChange={(e) => this.onChangeHighLevelToggle(e, tab.title)}
                  checked={tab.enabled}
                  type="checkbox"
                  disabled={this.state.reconfigInProgress}
                  className={tab.enabled === false ? ' disabledTitle ' : 'tabTitleLabel'}
                  label={tab.title}
                  id={'checkbox_' + tab.title.toLowerCase()}
                />
              </Form.Group>
            </div>
            <div style={{ marginTop: '30px' }}>
              {'targetDiff' in tab && tab.targetDiff !== null ? (
                <div style={{ display: 'flex' }}>
                  <div>
                    <FormControl
                      onChange={(e) => this.onChangeHighLevelTargetDiff(e, tab.title)}
                      value={tab.targetDiff !== null ? tab.targetDiff : ''}
                      autoComplete="off"
                      id="targetDiffInput"
                      placeholder="Difference"
                      type="number"
                      onKeyPress={this.handleTargetChangeKeyPress}
                      disabled={this.state.reconfigInProgress || tab.enabled === false}
                      aria-describedby="basic-addon1"
                      style={{
                        textAlign: 'center',
                        borderRadius: 0,
                        border: 'none',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    />
                  </div>
                  <div style={{ alignSelf: 'center', marginLeft: '5px' }}>
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => {
                        this.handleReConfig();
                      }}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: '27px',
                    fontWeight: 'bold',
                    color: '#28a745',
                  }}
                >
                  Rank #1
                </div>
              )}
            </div>
          </div>
        </DragTab>
      );
      panelTemplate.push(<Panel key={index}>{tab.content}</Panel>);
    });

    return (
      <React.Fragment>
        <TopMenu
          handlenavigationroutes={(routeName) => {
            // abortRequests();
            this.props.history.push(routeName);
          }}
          history={this.props.history}
          activeKey="/triggers"
          isReevaluate={
            'is_re_evaluate' in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
        />

        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <div style={{ marginTop: '20px' }}>
                {this.state.isEditTBName === false ? (
                  <h3
                    onClick={() => {
                      this.setState({ isEditTBName: true });
                    }}
                  >
                    [[ <span className="text-primary">{this.state.triggerBatchName}</span> ]]
                  </h3>
                ) : (
                  <Form onSubmit={this.updateTBName}>
                    <InputGroup id="ig_triname">
                      <Form.Control
                        required
                        type="text"
                        name="triggerBatchName"
                        defaultValue={this.state.triggerBatchName}
                        placeholder="Enter Trigger Batch Name Here"
                      />
                      <InputGroup.Append>
                        <Button
                          variant="danger"
                          onClick={() => {
                            this.setState({ isEditTBName: false });
                          }}
                        >
                          <span>Cancel</span>
                        </Button>

                        <Button type="submit" variant="primary">
                          {this.state.updatingTBName === true ? (
                            <Spinner style={{ width: '20px', height: '20px' }} animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          ) : (
                            <span>Update</span>
                          )}
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form>
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
              {this.state.isLoadingConfiguration === true ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <Tabs
                  showModalButton={false}
                  customStyle={customStyle}
                  activeIndex={activeIndex}
                  onTabChange={(index) => this.setState({ activeIndex: index })}
                  onTabSequenceChange={this.handleTabSequenceChange}
                >
                  <DragTabList>{tabsTemplate}</DragTabList>
                  <PanelList>{panelTemplate}</PanelList>
                </Tabs>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default TriggerBatch;
