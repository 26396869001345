export const domainName = window.location.hostname;
export const apiBaseUrlLocalBoxContent = 'https://api.palletiq.com/v1/';
const switchDomain = 1;

export const siteColors = [
  '#FFFFFF',
  '#00743F',
  '#F4874B',
  '#6A8A82',
  '#A37C27',
  '#72A2C0',
  '#0D3C55',
  '#1ABC9C',
  '#040C0E',
  '#0444BF',
  '#A7414A',
  '#6465A5',
  '#F20050',
  '#2196F3',
  '#009688',
];

export const apiBaseUrlLocal =
  process.env.NODE_ENV === 'development'
    ? switchDomain === 1
      ? 'http://localhost:4001/v1/'
      : switchDomain === 2
        ? 'https://api.emptyshelves.com/v1/'
        : 'https://api.palletiq.com/v1/'
    : domainName === 'piq-admin.lavneet.com'
      ? 'https://api.palletiq.com/v1/'
      : domainName === 'admin.blueinkbooks.com'
        ? 'https://api.palletiq.com/v1/'
        : domainName === 'admin-dev.palletiq.com'
          ? 'https://api-dev.palletiq.com/v1/'
          : domainName === 'admin.brandonsbooks.com'
            ? 'https://api.brandonsbooks.com/v1/'
            : domainName === 'admin.palletiq.com'
              ? 'https://api.palletiq.com/v1/'
              : domainName === 'admin.myemptyshelves.com'
                ? 'https://api.emptyshelves.com/v1/'
                : domainName === 'admin.emptyshelves.com'
                  ? 'https://api.emptyshelves.com/v1/'
                  : domainName === 'admin.bookteriors.com'
                    ? 'https://api.bookteriors.com/v1/'
                    : 'https://api.mybookbin.co/v1/';
export const homePageEmail = domainName === 'palletiq.com' ? 'caleb@thebookflipper.com' : 'info@myemptyshelves.com';
