import React, { Component } from 'react';
import { Row, Col, Form, Modal, Button, InputGroup } from 'react-bootstrap';

class ModalAddTrigger extends Component {
  state = {
    isCeiling1Enabled: false,
    isCeiling2Enabled: false,
    isCeiling3Enabled: false,
    isCeilingKeepaEnabled: false,
    isSkipEscore: false,
    isMMEnabled: false,
  };
  constructor() {
    super();
    this.handleC1Change = this.handleC1Change.bind(this);
    this.handleC2Change = this.handleC2Change.bind(this);
    this.handleC3Change = this.handleC3Change.bind(this);
    this.handleCKeepaChange = this.handleCKeepaChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.onFormSubmitAddTrigger = this.onFormSubmitAddTrigger.bind(this);
  }

  handleC1Change(evt) {
    this.setState({ isCeiling1Enabled: evt.target.checked });
  }
  handleC2Change(evt) {
    this.setState({ isCeiling2Enabled: evt.target.checked });
  }
  handleC3Change(evt) {
    this.setState({ isCeiling3Enabled: evt.target.checked });
  }
  handleCKeepaChange(evt) {
    this.setState({ isCeilingKeepaEnabled: evt.target.checked });
  }

  handleOpen() {
    this.setState({ isCeiling1Enabled: false, isCeiling2Enabled: false });
  }

  onFormSubmitAddTrigger(event) {
    event.preventDefault();

    const slotKey = 'newSlot' in event.target.elements ? 'new_slot' : 'used_slot';
    const slotValue =
      'newSlot' in event.target.elements ? event.target.elements.newSlot.value : event.target.elements.usedSlot.value;

    // Gather required fields
    const triggerName = event.target.elements.triggerName.value;
    const isCeiling1Options = event.target.elements.isCeiling1Enabled.checked;
    const isCeilingKeepaOptions =
      'isCeilingKeepaEnabled' in event.target.elements ? event.target.elements.isCeilingKeepaEnabled.checked : false;
    const isMMEnabled = 'isMMEnabled' in event.target.elements ? event.target.elements.isMMEnabled.checked : false;

    let triggerJson = {
      min_escore: event.target.elements.minEscore.value,
      max_escore: event.target.elements.maxEscore.value,
      min_rank: event.target.elements.minRank.value,
      max_rank: event.target.elements.maxRank.value,
      always_reject: event.target.elements.isAlwaysReject.checked,
      amz_off_per: event.target.elements.amzOffPer.value,
      target_profit: event.target.elements.targetProfit.value,
      is_skip_escore: event.target.elements.isSkipEscore.checked,
      ceiling_1_option: isCeiling1Options ? event.target.elements.ceiling1Options.value : '',
      ceiling_1_discount: isCeiling1Options ? event.target.elements.ceiling1Discount.value : 0,
      ceiling_keepa_option: isCeilingKeepaOptions ? event.target.elements.ceilingKeepaOptions.value : '',
      ceiling_keepa_bump_up_dollars: isCeilingKeepaOptions ? event.target.elements.ceilingKeepaBumpUpDollars.value : 0,
      ceiling_keepa_bump_up_percentage: isCeilingKeepaOptions
        ? event.target.elements.ceilingKeepaBumpUpPercentage.value
        : 0,
      is_mm: isMMEnabled,
      mm_val: isMMEnabled ? event.target.elements.mm_option.value : 1,
    };

    triggerJson[slotKey] = slotValue;

    this.props.onFormSubmitAddTrigger(triggerName, triggerJson);
  }

  render() {
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showAddModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitAddTrigger}>
          <Modal.Header closeButton>
            <Form.Control
              required
              type="text"
              name="triggerName"
              className="input-text-trigger-name"
              placeholder="Enter Trigger Name Here"
            />
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: '13px', fontWeight: '500' }}>
              <Row style={{ marginBottom: '20px' }}>
                <Col md={12}>
                  <Form.Check
                    onChange={(e) => this.setState({ isSkipEscore: e.target.checked })}
                    type="switch"
                    name="isSkipEscore"
                    id="is-skip-escore"
                    label="Skip eScore?"
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={3}>
                      <Form.Group controlId="formMinScore">
                        <Form.Label>Min eScore</Form.Label>
                        <Form.Control
                          disabled={this.state.isSkipEscore}
                          required
                          type="number"
                          step="0.01"
                          name="minEscore"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formMaxScore">
                        <Form.Label>Max eScore</Form.Label>
                        <Form.Control
                          disabled={this.state.isSkipEscore}
                          required
                          type="number"
                          step="0.01"
                          name="maxEscore"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={3}>
                      <Form.Group controlId="formMinRank">
                        <Form.Label>Min SalesRank</Form.Label>
                        <Form.Control required type="number" step="0.01" name="minRank" />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId="formMaxRank">
                        <Form.Label>Max SalesRank</Form.Label>
                        <Form.Control required type="number" step="0.01" name="maxRank" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row></Row>

                  <Row>
                    <Col md={4}>
                      {this.props.triggerCatName.includes('_new_') ? (
                        <Form.Group controlId="formNewSlot">
                          <Form.Label>New Slot</Form.Label>
                          <Form.Control required type="number" step="0.01" name="newSlot" placeholder="New Slot" />
                          <Form.Text className="text-muted">
                            Enter <strong>-1</strong> to disable.
                          </Form.Text>
                        </Form.Group>
                      ) : (
                        <Form.Group controlId="formUsedSlot">
                          <Form.Label>Used Slot</Form.Label>
                          <Form.Control required type="number" step="0.01" name="usedSlot" placeholder="Used Slot" />
                          <Form.Text className="text-muted">
                            Enter <strong>-1</strong> to disable.
                          </Form.Text>
                        </Form.Group>
                      )}
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formAmzPer">
                        <Form.Label>Amazon Off %</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control required type="number" step="0.01" name="amzOffPer" />
                          <InputGroup.Append>
                            <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>

                        {/* <Form.Control required type="number" step="0.01" name="amzOffPer" placeholder="Amazon Off %" /> */}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formTargetProfit">
                        <Form.Label>Target Profit ($)</Form.Label>

                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{ background: '#007bff', color: '#fff', fontWeight: 'bold' }}
                              id="per-symbol"
                            >
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            style={{ background: '#007bff', color: '#fff', fontWeight: 'bold' }}
                            required
                            type="number"
                            step="0.01"
                            name="targetProfit"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ height: '50px' }}>
                    <Col style={{ paddingTop: '8px' }} md={3}>
                      <Form.Group controlId="formIsCeiling1Compare">
                        <Form.Check
                          onChange={this.handleC1Change}
                          type="switch"
                          name="isCeiling1Enabled"
                          id="is-ceiling-1"
                          label="Ceiling 1?"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {this.state.isCeiling1Enabled && (
                        <Form.Group controlId="formCeiling1Options">
                          <Form.Control as="select" name="ceiling1Options">
                            <option>...</option>
                            <option>Lowest New Price</option>
                            <option>New Buy Box</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </Col>
                    <Col md={5}>
                      {this.state.isCeiling1Enabled && (
                        <InputGroup>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="ceiling1Discount"
                            placeholder="Ceiling 1 Discount %"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      )}
                    </Col>
                  </Row>

                  {this.props.keepaFlag === true &&
                    this.props.domainName !== 'admin.emptyshelves.com' &&
                    this.props.domainName !== 'admin.bookteriors.com' && (
                      <React.Fragment>
                        <Row style={{ height: '50px' }}>
                          <Col style={{ paddingTop: '8px', paddingRight: '10' }} md={3}>
                            <Form.Group controlId="formIsCeilingKeepaCompare">
                              <Form.Check
                                onChange={this.handleCKeepaChange}
                                type="switch"
                                name="isCeilingKeepaEnabled"
                                id="is-ceiling-keepa"
                                label="Keepa Ceiling?"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            {this.state.isCeilingKeepaEnabled && (
                              <Form.Group controlId="formCeiling2Options">
                                <Form.Control style={{ fontSize: '13px' }} as="select" name="ceilingKeepaOptions">
                                  <option>...</option>
                                  <option>Median</option>
                                  <option>Percentile 80</option>
                                  <option>Percentile 20</option>
                                </Form.Control>
                              </Form.Group>
                            )}
                          </Col>
                          <Col md={3}>
                            {this.state.isCeilingKeepaEnabled && (
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text id="per-symbol">$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  placeholder="Bump$"
                                  required
                                  type="number"
                                  step="0.01"
                                  name="ceilingKeepaBumpUpDollars"
                                />
                              </InputGroup>
                            )}
                          </Col>
                          <Col md={3}>
                            {this.state.isCeilingKeepaEnabled && (
                              <InputGroup>
                                <Form.Control
                                  placeholder="Bump%"
                                  required
                                  type="number"
                                  step="0.01"
                                  name="ceilingKeepaBumpUpPercentage"
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            )}
                          </Col>
                        </Row>

                        <Row style={{ height: '50px' }}>
                          <Col md={5} style={{ paddingTop: '8px' }}>
                            <Form.Group controlId="formIsMMCompare">
                              <Form.Check
                                onChange={(evt) => {
                                  this.setState({ isMMEnabled: evt.target.checked });
                                }}
                                type="switch"
                                name="isMMEnabled"
                                id="is-mm"
                                label="Enable Marketplace Multiplier?"
                              />
                            </Form.Group>
                          </Col>

                          <Col md={7}>
                            {this.state.isMMEnabled && (
                              <InputGroup>
                                <Form.Control required type="number" step="0.01" name="mm_option" />
                                <InputGroup.Append>
                                  <InputGroup.Text id="per-symbol">X Target Profit</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            )}
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}

                  <Row>
                    <Col md={6}>
                      <Form.Check type="switch" name="isAlwaysReject" id="always-reject" label="Always Reject?" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAddTrigger;
