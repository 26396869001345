import { apiBaseUrlLocal } from './AppConfig';

export const getFormattedDate = (dtRaw) => {
  if (dtRaw) {
    const dt = new Date(dtRaw);

    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;

    var yyyy = dt.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }

    return yyyy + '-' + mm + '-' + dd;
  } else return '';
};

export const getMPSources = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/shipments/marketplace', {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};

export const updateCompleteMPSource = async (admin_data, mpSourceId, batchData) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/shipments/marketplace/update-row', {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      mp_source_id: mpSourceId,
      batch_data: JSON.stringify(batchData),
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};

export const addCompleteMPSource = async (admin_data, mpSourceId, batchData) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/shipments/marketplace/add-row', {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      mp_source_id: mpSourceId,
      batch_data: JSON.stringify(batchData),
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};

export const deleteMPSource = async (admin_data, mpSourceId) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/shipments/marketplace', {
    method: 'DELETE',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      mp_source_id: mpSourceId,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};

export const downloadManifest = async (admin_data, mpSourceName) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/shipments/marketplace/manifest', {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      mp_source_name: mpSourceName,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};

export const updateMPSource = async (admin_data, data) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/shipments/marketplace', {
    method: 'PUT',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      mp_source_id: data.mpSourceId,
      field: data.fieldName,
      new_value: data.newValue,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};

export const createNewRow = async (admin_data, data) => {
  const response = await fetch(apiBaseUrlLocal + 'admin/shipments/marketplace/new-source', {
    method: 'POST',
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();

    return data;
  } else return false;
};
