import React, { Component } from 'react';
import {
  loadTriggers,
  booksCategoryList,
  eventToTriggerJson,
  updateTrigger,
  addTrigger,
  deleteTrigger,
  resetTriggerBatch,
} from '../../../includes/HelperTriggers';
import { Container, Row, Col, Button, Spinner, InputGroup, FormControl, Form } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { reconfigTB } from '../../../includes/HelperTriggerBatches';

import TableListTriggers from '../Modals/TriggersAmazonFBA/TableListTriggers';
import ModalEditTrigger from '../Modals/TriggersAmazonFBA/ModalEditTrigger';
import ModalAddTrigger from '../Modals/TriggersAmazonFBA/ModalAddTrigger';
import ModalSettings from '../Modals/Others/ModalSettings';

import ColorTextPicker from '../../Others/ColorTextPicker';

class AmzFBATriggers extends Component {
  state = {
    triggers: [],
    triggerRows: [],
    showEditModal: false,
    showAddModal: false,
    editTrigger: { TriggerName: '', jsonData: {} },
    categoryList: [],
    triggerTypeId: -1,
    triggerCatName: '',
    triggerBatchId: -1,
    isLoading: false,
    isTriggerEnabled: false,
    config_amazon: [],
    targetDiff: 0,
    thisTabIndex: -1,
    reconfigInProgress: false,
    showSettingsModal: false,
    customText: '',
    customColor: '',
    domainName: window.location.hostname,
  };

  constructor() {
    super();
    this.onFormSubmitEditTrigger = this.onFormSubmitEditTrigger.bind(this);
    this.onFormSubmitAddTrigger = this.onFormSubmitAddTrigger.bind(this);
    this.onClickDeleteTrigger = this.onClickDeleteTrigger.bind(this);
    this.initiateLoadTriggers = this.initiateLoadTriggers.bind(this);
    this.handleReConfig = this.handleReConfig.bind(this);
    this.toggleCurrent = this.toggleCurrent.bind(this);
  }

  componentDidMount() {
    /**
     * This is what executes before everything when the page loads.
     * What we're doing here:
     * 1. calling API to load/set triggers
     */
    this.setState(
      {
        triggerTypeId: this.props.triggersTypeId,
        triggerCatName: this.props.triggerCatName,
        triggerBatchId: this.props.triggerBatchId,
      },
      this.initiateLoadTriggers
    );

    if (
      this.props.triggerCatName in this.props.batchConfiguration &&
      this.props.batchConfiguration[this.props.triggerCatName] !== null
    ) {
      const config_amazon = JSON.parse(this.props.batchConfiguration[this.props.triggerCatName]);
      this.setState({ config_amazon });

      const index = config_amazon.findIndex(function (module) {
        return module.module_name === 'FBA';
      });
      if (index >= 0) {
        this.setState({ thisTabIndex: index });

        const fba_config = config_amazon[index];

        if ('enabled' in fba_config) {
          let target_diff = -1;
          if (fba_config.enabled === true) target_diff = fba_config.target_diff;
          this.setState({
            isTriggerEnabled: fba_config.enabled,
            targetDiff: target_diff,
            customText: 'text' in fba_config ? fba_config.text : '',
            customColor: 'color' in fba_config ? fba_config.color : '',
          });
        }
      }
    }
  }

  initiateLoadTriggers() {
    this.fetchTriggers();
    this.setState({ categoryList: booksCategoryList });
  }

  /**
   * Reads Triggers from Datbase using our API and saves it to state.
   * Should we use Redux for this??
   */
  async fetchTriggers() {
    this.setState({ isLoading: true });
    const triggersList = await loadTriggers(this.props.admin_data, this.state.triggerBatchId, this.state.triggerTypeId);
    this.setState({ triggers: triggersList, isLoading: false });
  }

  handleEditShow = (trigger, _index) => {
    let triggerClone = trigger;
    triggerClone.jsonData = JSON.parse(trigger.TriggerJson);
    triggerClone.categories = booksCategoryList;
    this.setState({ showEditModal: true, editTrigger: triggerClone });
  };

  /**
   * Gets called when user submits 'Edit Trigger' form from the Modal Dialog.
   * @param {formparameters} event
   */
  async onFormSubmitEditTrigger(event) {
    event.preventDefault();

    // Gather required fields
    const triggerId = this.state.editTrigger.Id;
    const triggerName = event.target.elements.triggerName.value;
    const triggerJson = eventToTriggerJson(event);
    // Call API to update trigger
    const response = await updateTrigger(
      this.props.admin_data,
      this.state.triggerBatchId,
      triggerId,
      triggerName,
      triggerJson,
      this.state.triggerTypeId
    );
    response !== false && response.status === 'success' && this.setState({ triggers: response.data });
    this.setState({ showEditModal: false });
  }

  /**
   * Gets called when user submits 'Add Trigger' form from the Modal Dialog.
   * @param {formparameters} event
   */
  async onFormSubmitAddTrigger(event) {
    event.preventDefault();

    // Gather required fields
    const triggerName = event.target.elements.triggerName.value;
    const triggerJson = eventToTriggerJson(event);
    // Call API to add trigger
    const response = await addTrigger(
      this.props.admin_data,
      this.state.triggerBatchId,
      triggerName,
      triggerJson,
      this.state.triggerTypeId
    );
    response !== false && response.status === 'success' && this.setState({ triggers: response.data });
    this.setState({ showAddModal: false });
  }

  async onClickDeleteTrigger(trigger, _index) {
    // Gather required fields
    const triggerId = trigger.Id;
    // Call API to delete trigger
    const response = await deleteTrigger(
      this.props.admin_data,
      this.state.triggerBatchId,
      triggerId,
      this.state.triggerTypeId
    );
    response !== false && response.status === 'success' && this.setState({ triggers: response.data });
    this.setState({ showAddModal: false });
  }

  async handleReConfig(color, text) {
    let config_amazon_clone = [...this.state.config_amazon];
    const index = config_amazon_clone.findIndex(function (module) {
      return module.module_name === 'FBA';
    });

    this.setState({ reconfigInProgress: true });
    const targetDiff = this.state.targetDiff;

    config_amazon_clone[index].target_diff = parseFloat(targetDiff);
    if (undefined !== color && undefined !== text) {
      config_amazon_clone[index].color = color;
      config_amazon_clone[index].text = text;
    }

    this.props.updateTBConfig(this.props.triggerCatName, config_amazon_clone);
    await reconfigTB(
      this.props.admin_data,
      this.props.triggerBatchId,
      this.props.triggerCatName,
      JSON.stringify(config_amazon_clone)
    );
    this.setState({ reconfigInProgress: false });
  }

  handleResetTriggerBatch() {
    confirmAlert({
      title: 'Reset Trigger Batch?',
      message:
        'This will permanently reset all the triggers in this batch to default settings. Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            this.setState({ isLoading: true });
            const triggersList = await resetTriggerBatch(
              this.props.admin_data,
              this.props.triggerBatchId,
              this.props.triggersTypeId
            );
            this.setState({ triggers: triggersList.data, isLoading: false });
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }

  async toggleCurrent(e) {
    this.setState({ isTriggerEnabled: e.target.checked, reconfigInProgress: true });

    let config_amazon_clone = [...this.state.config_amazon];
    const index = config_amazon_clone.findIndex(function (module) {
      return module.module_name === 'FBA';
    });
    config_amazon_clone[index].enabled = e.target.checked;
    this.setState({ isTriggerEnabled: e.target.checked });

    this.props.updateTBConfig(this.props.triggerCatName, config_amazon_clone);

    await reconfigTB(
      this.props.admin_data,
      this.props.triggerBatchId,
      this.props.triggerCatName,
      JSON.stringify(config_amazon_clone)
    );
    this.setState({ reconfigInProgress: false });
  }

  render() {
    return (
      <React.Fragment>
        <ModalEditTrigger
          domainName={this.state.domainName}
          keepaFlag={this.props.admin_data.is_keepa}
          premiumLevel={this.props.admin_data.premium_level}
          showEditModal={this.state.showEditModal}
          triggerCatName={this.props.triggerCatName}
          handleClose={() => this.setState({ showEditModal: false })}
          onFormSubmitEditTrigger={this.onFormSubmitEditTrigger}
          editTrigger={this.state.editTrigger}
        />
        <ModalAddTrigger
          domainName={this.state.domainName}
          keepaFlag={this.props.admin_data.is_keepa}
          premiumLevel={this.props.admin_data.premium_level}
          showAddModal={this.state.showAddModal}
          triggerCatName={this.props.triggerCatName}
          handleClose={() => this.setState({ showAddModal: false })}
          onFormSubmitAddTrigger={this.onFormSubmitAddTrigger}
        />
        <ModalSettings
          admin_data={this.props.admin_data}
          domainName={this.state.domainName}
          showSettingsModal={this.state.showSettingsModal}
          triggerBatchId={this.state.triggerBatchId}
          triggerTypeId={this.state.triggerTypeId}
          handleClose={() => this.setState({ showSettingsModal: false })}
        />
        <Container fluid={true} style={{ padding: '0' }}>
          <Row style={{ marginTop: '10px', paddingBottom: '15px' }}>
            <Col md={5}>
              {this.state.isTriggerEnabled === true && this.props.admin_data.premium_level === 1 && (
                <div style={{ marginBottom: '20px' }}>
                  <ColorTextPicker
                    customColor={this.state.customColor}
                    customText={this.state.customText}
                    updateCustomTextColor={async (color, text) => {
                      await this.handleReConfig(color, text);
                    }}
                    updatingCustomTextColor={this.state.reconfigInProgress}
                  />
                </div>
              )}

              <div style={{ float: 'left', padding: '7px 50px 0 0' }}>
                <Form.Check
                  onChange={this.toggleCurrent}
                  checked={this.state.isTriggerEnabled}
                  type="switch"
                  disabled={this.state.reconfigInProgress}
                  id="enable-trigger"
                  label="Enable?"
                />
              </div>

              {this.state.isTriggerEnabled === true && this.state.thisTabIndex > 0 && (
                <InputGroup id="ig_asin" style={{ width: '250px' }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    value={this.state.targetDiff}
                    onChange={(e) => {
                      this.setState({ targetDiff: e.target.value });
                    }}
                    autoComplete="off"
                    id="targetDiffInput"
                    placeholder="Difference"
                    type="number"
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Append id="btn_asin_search">
                    <Button onClick={this.handleReConfig} disabled={this.state.reconfigInProgress}>
                      {this.state.reconfigInProgress === true ? (
                        <Spinner style={{ width: '20px', height: '20px' }} animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <span>Save</span>
                      )}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              )}
            </Col>

            {this.state.isTriggerEnabled === true && (
              <Col md={7}>
                <div style={{ textAlign: 'right', marginTop: '5px' }}>
                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: '8px' }}
                    variant="warning"
                    onClick={() => this.handleResetTriggerBatch()}
                  >
                    Reset to defaults
                  </Button>

                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: '8px' }}
                    variant="secondary"
                    onClick={() => this.setState({ showSettingsModal: true })}
                  >
                    Settings
                  </Button>
                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: '8px' }}
                    variant="success"
                    onClick={() => this.setState({ showAddModal: true })}
                  >
                    Add New Trigger
                  </Button>
                </div>
              </Col>
            )}
          </Row>

          {this.state.isTriggerEnabled === true && (
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                {this.state.isLoading ? (
                  <div style={{ textAlign: 'center', paddingTop: '100px' }}>
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <TableListTriggers
                    triggerCatName={this.props.triggerCatName}
                    triggers={this.state.triggers}
                    handleEditShow={this.handleEditShow}
                    deleteTrigger={this.onClickDeleteTrigger}
                  />
                )}
              </Col>
            </Row>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default AmzFBATriggers;
